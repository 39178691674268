import { SchemaSection } from '@rossum/api-client/schemas';
import { LinearProgress } from '@rossum/ui/material';
import {
  DataGridPro,
  gridClasses,
  GridNoRowsOverlay,
} from '@rossum/ui/x-data-grid-pro';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { mergeDeep } from 'remeda';
import {
  commonDataGridStyles,
  virtualScrollerOnResizeStyles,
} from '../../../ui/data-grid/styles';
import CustomNoRowsOverlay from './components/CustomNoRowsOverlay';
import { useCalculatePreview } from './hooks/useCalculatePreview';
import { useColumns } from './hooks/useColumns';
import { useUpdateColumWidth } from './hooks/useColumnWidths';
import { useGetAnnotationsForPreview } from './hooks/useGetAnnotationsForPreview';
import { documentsColumn, useRows } from './hooks/useRows';

const gridStyles = mergeDeep(
  { ...commonDataGridStyles, ...virtualScrollerOnResizeStyles },
  {
    // fix cropped empty NoRowsOverlay
    [`.${gridClasses.virtualScroller}`]: {
      minHeight: 105,
    },
  }
);

export type FormulaPreviewGridProps = {
  annotationsQuery: ReturnType<typeof useGetAnnotationsForPreview>;
  currentFormulaId: string;
  schemaForPreview: SchemaSection[];
};

export const FormulaPreviewGrid = ({
  annotationsQuery,
  currentFormulaId,
  schemaForPreview,
}: FormulaPreviewGridProps) => {
  const intl = useIntl();

  const annotationUrls = useMemo(() => {
    return (
      annotationsQuery.data?.pages.flatMap(page =>
        page.results.map(({ url }) => url)
      ) ?? []
    );
  }, [annotationsQuery.data]);

  const evaluationQueries = useCalculatePreview(
    annotationUrls,
    schemaForPreview
  );

  const columns = useColumns({ currentFormulaId, schemaForPreview });
  const rows = useRows({ columns, annotationsQuery, evaluationQueries });

  const isIdle = evaluationQueries.some(
    query => query.isLoading && !query.isFetching
  );

  const updateColumnWidth = useUpdateColumWidth();

  return (
    <DataGridPro
      slots={{
        loadingOverlay: LinearProgress,
        noRowsOverlay: isIdle ? CustomNoRowsOverlay : GridNoRowsOverlay,
      }}
      slotProps={{
        noRowsOverlay: {
          sx: { backgroundColor: 'inherit' },
        },
        loadingOverlay: {
          color: 'secondary',
        },
      }}
      localeText={
        rows.length === 0
          ? {
              noRowsLabel: intl.formatMessage({
                id: 'features.queueSettings.fields.formulaPreview.noAnnotations',
              }),
            }
          : undefined
      }
      autoHeight
      loading={
        annotationsQuery.isFetching ||
        evaluationQueries.some(query => query.isFetching)
      }
      columns={columns}
      rows={isIdle ? [] : rows}
      onColumnWidthChange={updateColumnWidth}
      density="compact"
      disableColumnMenu
      disableColumnReorder
      hideFooter
      disableRowSelectionOnClick
      initialState={{
        pinnedColumns: { right: [documentsColumn] },
      }}
      sx={gridStyles}
    />
  );
};
