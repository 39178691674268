import { endpoints, Url, withSideload } from '@rossum/api-client';
import { useInfiniteQuery } from '@tanstack/react-query';
import { api } from '../../../../lib/apiClient';
import { asScalar, parse } from '../../../../lib/url';
import { encodeMqlQueryItem } from '../../../document-list/filtering/mql';
import { ACCESSIBLE_ANNOTATIONS_FILTER_ITEM } from '../../../pricing/utils';
import { FORMULA_PREVIEW_KEY } from '../../keys';

export const PAGE_SIZE = 5;

const query = {
  pageSize: PAGE_SIZE,
  ordering: '-created_at',
};

export const useGetAnnotationsForPreview = (queueUrls: Url[]) => {
  const mqlQueryPayload = {
    query: {
      $and: [
        {
          status: {
            $in: ['to_review', 'reviewing'],
          },
        },
        {
          queue: { $in: queueUrls },
        },
        ...encodeMqlQueryItem(ACCESSIBLE_ANNOTATIONS_FILTER_ITEM),
      ],
    },
  };

  const annotationsQuery = useInfiniteQuery({
    queryKey: [FORMULA_PREVIEW_KEY, 'annotations', mqlQueryPayload] as const,
    queryFn: ({ pageParam: searchAfter }) =>
      api.request(
        withSideload(
          endpoints.annotations.search(
            { ...query, searchAfter },
            mqlQueryPayload
          ),
          { documents: true }
        )
      ),
    getNextPageParam: data => {
      if (data.pagination.next) {
        return asScalar(parse(data.pagination.next).search_after);
      }

      return undefined;
    },
    cacheTime: 0, // clears data on closing drawer
    enabled: queueUrls.length > 0,
  });

  return annotationsQuery;
};
