const FIELDS_PARAM_KEY = 'fieldId';
const SCHEMA_ID_PARAM_KEY = 'schemaId';
const PARENT_ID_PARAM_KEY = 'parentId';

export type FieldManagerDetailParams = Record<typeof FIELDS_PARAM_KEY, string>;

export type FieldManagerCreateFieldParams = {
  [SCHEMA_ID_PARAM_KEY]: string;
  [PARENT_ID_PARAM_KEY]: string;
};

export const fieldDetailsRoute = <T extends string>(elisPathname: T) =>
  `${elisPathname}/detail/:${FIELDS_PARAM_KEY}` as const;

export const fieldCreateRoute = <T extends string>(elisPathname: T) =>
  `${elisPathname}/schema/:${SCHEMA_ID_PARAM_KEY}/parent/:${PARENT_ID_PARAM_KEY}/create-field` as const;

export const getFieldTitleFromPath = (pathname: string) =>
  pathname.includes('/detail') ? pathname.split('/detail/')[1] : null;
