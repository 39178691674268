import { useEffect, useMemo, useState } from 'react';
import { SurveyName } from '../../business/surveys/surveysUuidMap';
import { DEV_FEATURES_ENABLED } from '../../constants/config';
import { DevToolsSync } from './DevToolsSync';
import { FieldsSurveyDialog } from './dialogs/FieldsSurveyDialog';
import { useSurveyIfEnabled } from './hooks/useSurveyIfEnabled';

const SURVEY_NAME = 'fieldManagerSurvey' satisfies SurveyName;

const FieldManagerSurvey = ({ allDataLoaded }: { allDataLoaded: boolean }) => {
  const [userEngaged, setUserEngaged] = useState(false);

  const surveyInfo = useSurveyIfEnabled({
    surveyName: SURVEY_NAME,
    userEngaged,
  });

  // we have sufficient engagement for survey feedback after 10s spent
  // w/ this component rendered and allDataLoaded === true
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (allDataLoaded) {
        setUserEngaged(true);
      }
    }, 10 * 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [allDataLoaded]);

  // For debugging.
  const surveyDevToolsAction = useMemo(
    () => ({
      type: `@@ELIS_DEVTOOLS/SET_SURVEY_STATE` as const,
      payload: [
        {
          label: 'Field Manager Survey',
          ...surveyInfo,
        },
      ],
    }),
    [surveyInfo]
  );

  return (
    <>
      {DEV_FEATURES_ENABLED && <DevToolsSync action={surveyDevToolsAction} />}
      {surveyInfo.survey && (
        <FieldsSurveyDialog
          open={surveyInfo.shouldViewSurvey}
          survey={surveyInfo.survey}
          viewBlockers={surveyInfo.viewBlockers}
          surveyName={SURVEY_NAME}
        />
      )}
    </>
  );
};

export default FieldManagerSurvey;
