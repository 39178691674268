import { InfoOutlined } from '@rossum/ui/icons';
import {
  Alert,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  List,
  Stack,
  Tooltip,
  Typography,
} from '@rossum/ui/material';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as R from 'remeda';
import { throwInfo } from '../../redux/modules/messages/actions';
import featureFlags from '../featureFlags.json';
import {
  getLocalUnleashOverrides,
  isFeatureFlagEnabled,
} from '../useFeatureFlag';

const entries = R.entries.strict(featureFlags);

const FeatureFlagToggle = () => {
  const intl = useIntl();

  const [selectedValues, setSelectedValues] = useState<Record<string, boolean>>(
    getLocalUnleashOverrides
  );

  const dispatch = useDispatch();

  const handleSubmit = () => {
    localStorage.setItem(
      'localUnleashOverrides',
      JSON.stringify(selectedValues)
    );

    dispatch(throwInfo('featureFlags'));
  };

  return (
    <Stack
      component="form"
      sx={{ height: '100%' }}
      spacing={2}
      onSubmit={handleSubmit}
    >
      <Alert variant="outlined" severity="warning">
        {intl.formatMessage({
          id: 'containers.personalInfo.changeFeatureFlags.warning',
        })}
      </Alert>

      <Stack spacing={3}>
        <FormControl fullWidth>
          <List disablePadding sx={{ whiteSpace: 'nowrap' }}>
            {entries.map(([featureName, { description }]) => {
              return (
                <FormGroup key={featureName}>
                  <FormControlLabel
                    control={
                      <Stack
                        direction="row"
                        alignItems="center"
                        sx={{
                          borderLeft: R.isDefined.strict(
                            selectedValues[featureName]
                          )
                            ? theme =>
                                `2px solid ${theme.palette.text.secondary}`
                            : '2px solid transparent',
                        }}
                      >
                        <Checkbox
                          onChange={e =>
                            setSelectedValues(prev => ({
                              ...prev,
                              [featureName]: e.target.checked,
                            }))
                          }
                          checked={
                            selectedValues[featureName] ??
                            isFeatureFlagEnabled(featureName, false)
                          }
                          id={featureName}
                        />
                        {R.isDefined.strict(selectedValues[featureName]) &&
                        isFeatureFlagEnabled(featureName, false) !==
                          selectedValues[featureName] ? (
                          <Tooltip title="This flag is overriden on your computer.">
                            <InfoOutlined color="info" sx={{ mr: 1 }} />
                          </Tooltip>
                        ) : null}
                      </Stack>
                    }
                    label={
                      <Stack flex={1} minWidth={0} maxWidth={600}>
                        <Stack direction="row" spacing={1}>
                          <Typography variant="body2" fontWeight="bold">
                            {featureName}
                          </Typography>
                        </Stack>

                        <Typography
                          variant="body2"
                          overflow="hidden"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          sx={{ color: 'text.secondary', flex: 1 }}
                        >
                          {description}
                        </Typography>
                      </Stack>
                    }
                  />
                </FormGroup>
              );
            })}
          </List>
          <Stack direction="row" justifyContent="center" spacing={1.5}>
            <Button
              variant="outlined"
              color="secondary"
              sx={{ alignSelf: 'center' }}
              style={{ marginTop: '16px' }}
              onClick={() => {
                localStorage.removeItem('localUnleashOverrides');
                setSelectedValues({});
              }}
            >
              {intl.formatMessage({
                id: 'containers.personalInfo.changeFeatureFlags.button.clear',
              })}
            </Button>

            <Button
              variant="contained"
              onClick={handleSubmit}
              sx={{ alignSelf: 'center' }}
              style={{ marginTop: '16px' }}
            >
              {intl.formatMessage({
                id: 'containers.personalInfo.changeFeatureFlags.button.submit',
              })}
            </Button>
          </Stack>
        </FormControl>
      </Stack>
    </Stack>
  );
};
export default FeatureFlagToggle;
