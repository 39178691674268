import { EmailThreadCounts } from '@rossum/api-client/emailThreads';
import { compact } from 'lodash';
import { useIntl } from 'react-intl';
import { dateValueGetter } from '../../columns/helpers';
import RenderDate from '../../columns/RenderDate';
import {
  emailOptionsOperator,
  emailsDateOperators,
} from '../../filtering/operators';
import { EmailFields, TypedGridColDef } from '../../filtering/types';
import CountsCell from '../components/CountsCell';
import SubjectCell from '../components/SubjectCell';

const defaultColumnConfig = {
  filterable: false,
  pinnable: false,
  disableReorder: true,
};

const optionsValues = [
  'hasNewReplies',
  'hasReplies',
  'recentWithNoDocumentsNotReplied',
] as const;

export const useEmailsColumns = (counts: EmailThreadCounts | undefined) => {
  const intl = useIntl();

  const pairedCounts: Record<(typeof optionsValues)[number], number> = {
    hasNewReplies: counts?.withNewReplies || 0,
    hasReplies: counts?.withReplies || 0,
    recentWithNoDocumentsNotReplied:
      counts?.recentWithNoDocumentsNotReplied || 0,
  };

  const columns: Array<TypedGridColDef> = compact([
    {
      field: 'subject' satisfies EmailFields,
      flex: 1,
      minWidth: 300,
      sortable: true,
      filterable: false,
      operators: [],
      renderCell: SubjectCell,
      headerName: intl.formatMessage({
        id: 'components.emailOverview.subject',
      }),
    },
    {
      field: 'from__email' satisfies EmailFields,
      width: 270,
      sortable: true,
      filterable: false,
      operators: [],
      headerName: intl.formatMessage({
        id: 'components.emailOverview.from',
      }),
    },
    {
      field: 'created_at' satisfies EmailFields,
      headerName: intl.formatMessage({
        id: 'components.emailOverview.createdAt',
      }),
      width: 170,
      sortable: true,
      filterable: true,
      operators: emailsDateOperators,
      renderCell: RenderDate,
      valueGetter: dateValueGetter,
    },
    {
      field: 'last_email_created_at' satisfies EmailFields,
      headerName: intl.formatMessage({
        id: 'components.emailOverview.lastEmailCreatedAt',
      }),
      width: 200,
      sortable: true,
      filterable: true,
      operators: emailsDateOperators,
      renderCell: RenderDate,
      valueGetter: dateValueGetter,
    },
    {
      field: 'counts' satisfies EmailFields,
      width: 90,
      sortable: false,
      operators: [],
      renderCell: CountsCell,
      headerName: '',
    },
    {
      field: 'options' satisfies EmailFields,
      headerName: intl.formatMessage({
        id: 'components.emailOverview.options',
      }),
      sortable: false,
      filterable: true,
      operators: [emailOptionsOperator],
      valueOptions: optionsValues.map(value => ({
        value,
        label: intl.formatMessage({
          id: `containers.emailOverview.label.${value}`,
        }),
        count: pairedCounts[value],
      })),
    },
  ]);

  return columns.map(col => ({ ...defaultColumnConfig, ...col }));
};
