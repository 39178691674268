import { Survey } from '@rossum/api-client/surveys';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resumeValidation } from '../../../redux/modules/beforeLeave/actions';
import { State } from '../../../types/state';
import { useDataCaptureSurveyActions } from './useDataCaptureSurveyActions';

type UseAnswerDataCaptureSurveyProps = {
  survey: Survey;
};

export const useAnswerDataCaptureSurvey = ({
  survey,
}: UseAnswerDataCaptureSurveyProps) => {
  const dispatch = useDispatch();

  const trigger = useSelector(
    (state: State) => state.beforeLeave.current?.trigger
  );

  const annotationId = useSelector(
    (state: State) => state.annotation.id ?? undefined
  );

  const additionalInfo = useMemo(
    () => ({
      annotationIds: [annotationId],
      trigger,
    }),
    [annotationId, trigger]
  );

  const {
    increaseViewCount,
    increaseAuroraUsageCount,
    increaseSkipCount,
    answer,
  } = useDataCaptureSurveyActions(survey, additionalInfo);

  const handleSkip = useCallback(() => {
    increaseSkipCount().finally(() => {
      dispatch(resumeValidation());
    });
  }, [dispatch, increaseSkipCount]);

  const handleSubmit = useCallback(
    (answers: { value: unknown }[]) => {
      answer(answers).finally(() => {
        dispatch(resumeValidation());
      });
    },
    [answer, dispatch]
  );

  return {
    increaseViewCount,
    increaseAuroraUsageCount,
    handleSkip,
    handleSubmit,
  };
};
