import { Box, Stack, Typography } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Redirect, useLocation } from 'react-router-dom';
import { z } from 'zod';
import { contactSupportLink, statusPageURL } from '../../constants/values';
import { link } from '../../lib/formaterValues';
import { parse } from '../../lib/url';
import LoginFailed from './LoginFailed';
import TooManyRequests from './TooManyRequests';

const reasonSchema = z.union([
  z.literal('login-failed'),
  z.literal('too-many-requests'),
]);

const componentsMap = {
  'too-many-requests': TooManyRequests,
  'login-failed': LoginFailed,
};

const ErrorPage = () => {
  const intl = useIntl();
  const location = useLocation();

  const { search } = location;
  const { reason } = parse(search);

  const parsed = reasonSchema.safeParse(reason);

  if (!parsed.success) {
    return <Redirect to="/" />;
  }

  const CustomReasonComponent = componentsMap[parsed.data];

  return (
    <Stack data-page-title={parsed.data}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          textAlign: 'center',
          padding: 2,
        }}
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Typography variant="h3" fontWeight="bold">
            {intl.formatMessage({
              id: `containers.errorPage.${parsed.data}.title`,
            })}
          </Typography>
          <Typography variant="h5" fontWeight="bold">
            {intl.formatMessage({
              id: `containers.errorPage.${parsed.data}.text`,
            })}
          </Typography>
        </Stack>

        <CustomReasonComponent />

        <Box>
          <Typography color="secondary" fontSize="small" sx={{ mt: '105px' }}>
            {intl.formatMessage(
              { id: 'containers.maintenance.badge.text1' },
              {
                contactLink: link(contactSupportLink, {
                  color: 'inherit',
                }),
                statusLink: link(statusPageURL, { color: 'inherit' }),
              }
            )}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default ErrorPage;
