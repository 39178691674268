import { get } from 'lodash';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useQueueSettingsContext } from '../../../../features/queue-settings/contexts/QueueSettingsContext';
import { clearValidationMessagesAction } from '../../../../redux/modules/validationMessages/action';
import { InputMessages } from '../../../../redux/modules/validationMessages/types';
import { QueueWithInboxObject } from '../../../../types/queue';
import { State } from '../../../../types/state';
import NoInboxPlaceholder from './NoInboxPlaceholder';
import UpdateInbox from './UpdateInbox';

type OwnProps = {
  selectedQueue: QueueWithInboxObject;
};

type StateProps = {
  validationMessages: InputMessages;
};

type DispatchProps = {
  clearValidationMessages: typeof clearValidationMessagesAction;
};

type Props = OwnProps & StateProps & DispatchProps;

const QueueEmails = ({
  selectedQueue,
  validationMessages,
  clearValidationMessages,
}: Props) => {
  const intl = useIntl();
  const { setHeaderMeta } = useQueueSettingsContext();

  useEffect(() => {
    setHeaderMeta(prevState => ({
      ...prevState,
      title: intl.formatMessage({
        id: `containers.settings.queues.queue.emails`,
      }),
      description: intl.formatMessage({
        id: `containers.settings.queues.queue.emails.description`,
      }),
      buttons: [],
    }));
  }, [intl, setHeaderMeta]);

  return selectedQueue?.inboxObject ? (
    <UpdateInbox
      inbox={selectedQueue.inboxObject}
      selectedQueue={selectedQueue}
      clearValidationMessages={clearValidationMessages}
      validationMessages={validationMessages}
    />
  ) : (
    <div data-page-title="queue-emails">
      <NoInboxPlaceholder
        clearValidationMessages={clearValidationMessages}
        validationMessages={validationMessages}
        selectedQueue={selectedQueue}
      />
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  validationMessages: get(state.validationMessages, ['inbox']),
});

const mapDispatchToProps = {
  clearValidationMessages: clearValidationMessagesAction,
};

const QueueEmailsContainer = connect<
  StateProps,
  DispatchProps,
  OwnProps,
  State
>(
  mapStateToProps,
  mapDispatchToProps
)(QueueEmails);

export default QueueEmailsContainer;
