import { Button } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Link as RouterLink } from 'react-router-dom';

const LoginFailed = () => {
  const intl = useIntl();

  return (
    <Button
      component={RouterLink}
      to="/"
      color="secondary"
      variant="text"
      sx={{ mt: 2 }}
      data-cy="error-page-back-button"
    >
      {intl.formatMessage({ id: 'components.user.recovery.back' })}
    </Button>
  );
};

export default LoginFailed;
