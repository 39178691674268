import { SchemaSection } from '@rossum/api-client/schemas';
import { Button, CircularProgress, Stack } from '@rossum/ui/material';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import CalculatePreviewButton from './CalculatePreviewButton';
import {
  PAGE_SIZE,
  useGetAnnotationsForPreview,
} from './hooks/useGetAnnotationsForPreview';

const MAX_COUNT = 20;
const PAGES_LIMIT = MAX_COUNT / PAGE_SIZE;

type FormulaPreviewProps = {
  annotationsQuery: ReturnType<typeof useGetAnnotationsForPreview>;
  schemaForPreview: SchemaSection[];
  disabled: boolean;
  renderGrid: (params: {
    annotationsQuery: ReturnType<typeof useGetAnnotationsForPreview>;
    schemaForPreview: SchemaSection[];
  }) => ReactNode;
};

const FormulaPreview = ({
  annotationsQuery,
  schemaForPreview,
  disabled,
  renderGrid,
}: FormulaPreviewProps) => {
  const intl = useIntl();

  const { data, hasNextPage, fetchNextPage, isFetching } = annotationsQuery;
  const isIdle = schemaForPreview.length === 0;

  return (
    <>
      {renderGrid({ annotationsQuery, schemaForPreview })}

      {isIdle ? (
        <Stack spacing={4} marginTop={4} textAlign="center" alignItems="center">
          <CalculatePreviewButton disabled={disabled} />
        </Stack>
      ) : null}

      {hasNextPage && (data?.pages.length ?? 0) < PAGES_LIMIT && !isIdle && (
        <Button
          onClick={() => fetchNextPage()}
          variant="outlined"
          color="secondary"
          sx={{ alignSelf: 'flex-end' }}
          disabled={isFetching || disabled}
          startIcon={
            isFetching && <CircularProgress size={16} color="inherit" />
          }
          data-cy="formula-preview-show-more"
        >
          {intl.formatMessage(
            {
              id: 'features.queueSettings.fields.formulaPreview.showMore',
            },
            {
              count: Math.min(
                (data?.pages[0]?.pagination.total ?? 0) -
                  (data?.pages.length ?? 0) * PAGE_SIZE,
                PAGE_SIZE
              ),
            }
          )}
        </Button>
      )}
    </>
  );
};

export default FormulaPreview;
