import { createSelector } from 'reselect';
import { SurveyName } from '../../business/surveys/surveysUuidMap';
import { isEmbedded } from '../../constants/config';
import { isNotNullOrUndefined } from '../../lib/typeGuards';
import { annotationSelector } from '../../redux/modules/annotation/selectors';
import { isTrialSelector } from '../../redux/modules/organization/selectors';
import { complexLineItemsEnabledSelector } from '../../redux/modules/ui/selectors';
import { userSelector } from '../../redux/modules/user/selectors';
import { State } from '../../types/state';

const blockersPerSurveyName: Partial<
  Record<
    SurveyName,
    (args: {
      isTrial: boolean;
      complexLineItemsEnabled: boolean;
    }) => Array<string | undefined>
  >
> = {
  dataCaptureSurvey: ({ isTrial }) => [isTrial ? undefined : 'not_trial'],
  auroraForLineItemsSurvey: ({ complexLineItemsEnabled }) => [
    complexLineItemsEnabled ? undefined : 'no_cli',
  ],
};

export const dataCaptureSurveyBlockersSelector = createSelector(
  annotationSelector,
  userSelector,
  isTrialSelector,
  complexLineItemsEnabledSelector,
  (_: State, surveyType: SurveyName) => surveyType,
  (
    currentAnnotation,
    currentUser,
    isTrial,
    complexLineItemsEnabled,
    surveyType
  ) => {
    const internalUser =
      currentUser.email && currentUser.email.endsWith('rossum.ai');

    const sampleDocument = currentAnnotation?.metadata?.sampleDocument;
    const embedded = isEmbedded();

    const commonBlockers = [
      // no survey in embedded mode
      embedded ? 'embedded' : undefined,
      // no survey for sample documents
      sampleDocument ? 'sample_document' : undefined,
      // do not show to internal users
      internalUser ? 'internal_user' : undefined,
    ].filter(isNotNullOrUndefined);

    const getBlockers = blockersPerSurveyName[surveyType] ?? (() => []);

    return [
      ...getBlockers({ isTrial: !!isTrial, complexLineItemsEnabled }),
      ...commonBlockers,
    ].filter(isNotNullOrUndefined);
  }
);
