import { SchemaSection } from '@rossum/api-client/schemas';
import { Divider, Stack, Tab, Tabs } from '@rossum/ui/material';
import { get } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import * as R from 'remeda';
import SearchInput from '../../../ui/search-input/SearchInput';
import useDebounce from '../../../utils/hooks/useDebounce';
import templateCompletions from '../FormulaEditor/completers/templateCompletions.json';
import { getNonTupleSchemaFields } from '../FormulaEditor/helpers';
import { OnInsertCallback } from './insertion-helpers';
import MenuListAll from './MenuListAll';
import MenuListFields from './MenuListFields';
import MenuListFormulas from './MenuListFormulas';
import { fuzzySearchMultipleWords } from './searchMatching';
import { useSuggestFormula } from './useSuggestFormula';

const templates = templateCompletions.flatMap(t =>
  t.example
    ? {
        example: t.example,
        description: t.description,
        title: t.caption,
        snippet: t.snippet,
      }
    : []
);

const tabs = ['all', 'fields', 'formulas'] as const;
type TabValue = (typeof tabs)[number];

const Suggester = ({
  onInsert,
  schemaContent,
  schemaObjectPath,
}: {
  onInsert: OnInsertCallback;
  schemaContent: SchemaSection[];
  schemaObjectPath: (number | string)[];
}) => {
  const intl = useIntl();
  const [searchValue, setSearchValue] = useState<string>('');
  const firstMenuItemRef = useRef<HTMLLIElement | null>(null);

  const [activeTab, setActiveTab] = useState<TabValue>('all');

  const schemaItemsWithSections = useRef(
    getNonTupleSchemaFields(schemaContent)
  ).current;

  const schemaItems = useRef(
    schemaItemsWithSections.flatMap(schemaItem =>
      schemaItem.category !== 'section' ? [schemaItem] : []
    )
  ).current;

  const filteredSchemaItems = useMemo(() => {
    return fuzzySearchMultipleWords(schemaItems, ['label', 'id'], searchValue);
  }, [schemaItems, searchValue]);

  const filteredTemplates = useMemo(() => {
    return searchValue
      ? fuzzySearchMultipleWords(
          templates,
          ['title', 'description'],
          searchValue
        )
      : templates;
  }, [searchValue]);

  const debouncedSearch = useDebounce(searchValue, 300);

  const fieldSchemaId = get(schemaContent, schemaObjectPath)?.id;

  // to bypass the validation of formula, we need to set it to empty string
  const schemaContentWithEmptyFormula = R.setPath(
    schemaContent,
    // @ts-expect-error can it even be typesafe??
    schemaObjectPath.concat('formula'),
    ''
  );

  const auroraSuggestionsQuery = useSuggestFormula({
    hint: debouncedSearch,
    fieldSchemaId,
    schemaContent: schemaContentWithEmptyFormula,
  });

  return (
    <Stack divider={<Divider />} sx={{ background: 'inherit' }}>
      <Stack sx={{ p: 2, pb: 0 }} spacing={1}>
        <SearchInput
          onChange={setSearchValue}
          onKeyDown={e => {
            if (e.key === 'ArrowDown') {
              firstMenuItemRef.current?.focus();
            }
          }}
          value={searchValue}
          placeholder={intl.formatMessage({
            id: 'features.queueSettings.fields.formulaEditor.suggester.search.placeholder',
          })}
          sx={{
            width: '100%',
          }}
          InputProps={{
            sx: {
              fontSize: ({ typography }) => typography.body2.fontSize,
            },
            autoComplete: 'off',
            autoFocus: true,
          }}
        />
        <Tabs
          value={activeTab}
          onChange={(_, newTab) => {
            setActiveTab(newTab);
          }}
        >
          {tabs.map(tabValue => (
            <Tab
              key={tabValue}
              label={intl.formatMessage({
                id: `features.queueSettings.fields.formulaEditor.suggester.tabs.${tabValue}`,
              })}
              sx={{
                minWidth: 'inherit',
                fontSize: ({ typography }) => {
                  return typography.pxToRem(13);
                },
              }}
              value={tabValue}
            />
          ))}
        </Tabs>
      </Stack>
      {activeTab === 'all' && (
        <MenuListAll
          onInsert={onInsert}
          search={searchValue}
          debouncedSearch={debouncedSearch}
          filteredSchemaItems={filteredSchemaItems}
          filteredTemplates={filteredTemplates}
          setFirstRef={firstMenuItemRef}
          auroraSuggestionsQuery={auroraSuggestionsQuery}
        />
      )}
      {activeTab === 'fields' && (
        <MenuListFields
          search={searchValue}
          schemaItems={
            searchValue ? filteredSchemaItems : schemaItemsWithSections
          }
          onInsert={onInsert}
          setFirstRef={firstMenuItemRef}
        />
      )}
      {activeTab === 'formulas' && (
        <MenuListFormulas
          onInsert={onInsert}
          setFirstRef={firstMenuItemRef}
          templates={filteredTemplates}
          search={searchValue}
        />
      )}
    </Stack>
  );
};

export default Suggester;
