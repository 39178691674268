import { createBrowserHistory } from 'history';
import { compact } from 'lodash';
import {
  applyMiddleware,
  compose,
  createStore,
  Middleware,
  Store,
} from 'redux';
import {
  createReduxHistoryContext,
  LOCATION_CHANGE,
} from 'redux-first-history';
import { combineEpics } from 'redux-observable';
import { isDevelop, isEmbedded } from '../constants/config';
import {
  authDelete$,
  authGetBlob$,
  authGetJSON$,
  authPatch$,
  authPost$,
  authPostBlob$,
  authPut$,
} from '../lib/api';
import { State } from '../types/state';
import { createOptimizedEpicMiddleware } from './createOptimizedEpicMiddleware';
import actionsWriterMiddleware from './middlewares/actionsWriterMiddleware';
import devToolsMiddleware from './middlewares/devToolsMiddleware';
import embeddedMiddleware from './middlewares/embeddedMiddleware';
import localStorageMiddleware from './middlewares/localStorageMiddleware';
import messagesMiddleware from './middlewares/messagesMiddleware';
import queryClientMiddleware from './middlewares/queryClientMiddleware';
import themeMiddleware from './middlewares/themeMiddleware';
import timeSpentMiddleware from './middlewares/timeSpentMiddleware';
import trackingMiddleware from './middlewares/trackingMiddleware';
import createRootReducer from './modules';
import annotationEpics from './modules/annotation/epics';
import annotationsEpic from './modules/annotations/epics';
import authEpic from './modules/auth/epics';
import bboxesEpics from './modules/bboxes/epics';
import beforeLeaveEpics from './modules/beforeLeave/epics';
import datapointsEpics from './modules/datapoints/epics';
import suggestedOperationsEpics from './modules/datapoints/suggestedOperations/epics';
import suggestedPositionsForValueEpics from './modules/datapoints/suggestedPositionsForValue/epics';
import emailEpics from './modules/email/epics';
import emailsEpics from './modules/emails/epics';
import extensionsEpics from './modules/extensions/epics';
import feedbackEpics from './modules/feedback/epics';
import gridEpics from './modules/grid/epics';
import groupsEpics from './modules/groups/epics';
import inboxEpics from './modules/inbox/epics';
import localeEpics from './modules/locale/epics';
import messagesEpics from './modules/messages/epics';
import noteEpics from './modules/notes/epics';
import organizationEpics from './modules/organization/epics';
import organizationGroupEpics from './modules/organizationGroup/epics';
import popupEpics from './modules/popup/epics';
import productTourEpics from './modules/productTour/epics';
import queueEpics from './modules/queues/epics';
import relationsEpics from './modules/relations/epics';
import repeateRequestEpics from './modules/repeatedRequests/epics';
import reportEpics from './modules/report/epics';
import schemaEpics from './modules/schema/epics';
import searchEpics from './modules/search/epics';
import stackEpics from './modules/stack/epics';
import statisticsEpics from './modules/statistics/epics';
import trackingObserver from './modules/tracking/stateObservers';
import uiEpics from './modules/ui/epics';
import userEpics from './modules/user/epics';
import usersEpics from './modules/users/epics';
import workspaceEpics from './modules/workspaces/epics';
import { RootActionType } from './rootActions';

const { createReduxHistory, routerMiddleware, routerReducer } =
  createReduxHistoryContext({ history: createBrowserHistory() });

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      maxAge: isDevelop ? 120 : 50,
      trace: isDevelop,
    })) ||
  compose;

const epicMiddleware = createOptimizedEpicMiddleware({
  dependencies: {
    authDelete$,
    authGetBlob$,
    authGetJSON$,
    authPatch$,
    authPost$,
    authPostBlob$,
    authPut$,
  },
});

const rootEpic = combineEpics(
  annotationEpics,
  annotationsEpic,
  authEpic,
  bboxesEpics,
  datapointsEpics,
  emailEpics,
  emailsEpics,
  extensionsEpics,
  feedbackEpics,
  gridEpics,
  groupsEpics,
  inboxEpics,
  localeEpics,
  messagesEpics,
  noteEpics,
  organizationEpics,
  organizationGroupEpics,
  queueEpics,
  reportEpics,
  schemaEpics,
  searchEpics,
  stackEpics,
  statisticsEpics,
  suggestedPositionsForValueEpics,
  suggestedOperationsEpics,
  trackingObserver,
  uiEpics,
  userEpics,
  productTourEpics,
  usersEpics,
  workspaceEpics,
  popupEpics,
  repeateRequestEpics,
  relationsEpics,
  beforeLeaveEpics
);

const configureStore = () => {
  const middlewares = compact<Middleware>([
    devToolsMiddleware,
    messagesMiddleware,
    actionsWriterMiddleware,
    isEmbedded() && embeddedMiddleware,
    routerMiddleware,
    epicMiddleware,
    timeSpentMiddleware,
    trackingMiddleware,
    localStorageMiddleware,
    themeMiddleware,
    queryClientMiddleware,
  ]);

  const _store: Store<State, RootActionType> = createStore(
    createRootReducer(routerReducer),
    composeEnhancers(applyMiddleware(...middlewares))
  );

  return _store;
};

const store = configureStore();
window.store = store;

export const history = createReduxHistory(store);

// this have to be started AFTER the store has been created
epicMiddleware.run(rootEpic);

// Emit location action on load - for backwards compatibility
// TODO refactor.
store.dispatch({
  type: LOCATION_CHANGE,
  payload: store.getState().router,
});

export default store;
