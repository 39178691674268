import { endpoints } from '@rossum/api-client';
import { SchemaSection } from '@rossum/api-client/schemas';
import { GridColDef } from '@rossum/ui/x-data-grid-pro';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { api } from '../../../../lib/apiClient';
import { FORMULA_PREVIEW_KEY } from '../../keys';
import datapointCellRenderer, {
  formulaPreviewClassName,
} from '../cells/DatapointCell';
import DocumentCell from '../cells/DocumentCell';
import HeaderCell from '../cells/HeaderCell';
import { useColumnWidths } from './useColumnWidths';
import { columnsPrefix, documentsColumn, globals, RowModel } from './useRows';
import { getSchemaIconTypeMap } from './utils';

export const COLUMNS_QUERY_KEY = [
  FORMULA_PREVIEW_KEY,
  'formulas-info',
] as const;

type UseColumnsParams = {
  currentFormulaId: string;
  schemaForPreview: SchemaSection[];
};

export const useColumns = ({
  currentFormulaId,
  schemaForPreview,
}: UseColumnsParams): GridColDef<RowModel>[] => {
  const intl = useIntl();

  const formulasInfoQuery = useQuery({
    queryKey: [...COLUMNS_QUERY_KEY, schemaForPreview],
    queryFn: () =>
      api.request(
        endpoints.internal.schemas.formulasInfo({
          // using getter to use the current schema concept when invalidated query with unsaved changes
          schemaContent: schemaForPreview,
        })
      ),
    enabled: schemaForPreview.length > 0,
    cacheTime: 0,
  });

  const columnWidths = useColumnWidths();

  const formulaDependenciesColumns = useMemo(() => {
    const schemaIconsMap = formulasInfoQuery.dataUpdatedAt
      ? getSchemaIconTypeMap(schemaForPreview)
      : {};

    const dependencies =
      formulasInfoQuery.data?.results.find(d => d.id === currentFormulaId)
        ?.dependencies ?? [];

    return dependencies.flatMap(dependencySchemaId => {
      const field = `${columnsPrefix}.${dependencySchemaId}`;

      return dependencySchemaId === currentFormulaId
        ? []
        : {
            field,
            headerName: dependencySchemaId,
            width: columnWidths[field] ?? 200,
            minWidth: 120,
            sortable: false,
            renderCell: datapointCellRenderer,
            renderHeader: () => (
              <HeaderCell
                headerName={dependencySchemaId}
                iconType={schemaIconsMap[dependencySchemaId] ?? null}
              />
            ),
          };
    });
  }, [
    currentFormulaId,
    schemaForPreview,
    formulasInfoQuery.data,
    formulasInfoQuery.dataUpdatedAt,
    columnWidths,
  ]);

  const currentFormulaColumn = `${columnsPrefix}.${currentFormulaId}`;

  return [
    {
      field: currentFormulaColumn,
      headerName: currentFormulaId,
      width: columnWidths[currentFormulaColumn] ?? 200,
      minWidth: 120,
      sortable: false,
      renderCell: datapointCellRenderer,
      cellClassName: formulaPreviewClassName,
      renderHeader: () => (
        <HeaderCell headerName={currentFormulaId} iconType="formula" />
      ),
    },
    ...formulaDependenciesColumns,
    {
      field: documentsColumn,
      headerName: intl.formatMessage({
        id: 'features.queueSettings.fields.formulaPreview.table.document',
      }),
      width: columnWidths[documentsColumn] ?? 200,
      minWidth: 200,
      sortable: false,
      renderHeader: p => (
        <HeaderCell headerName={p.colDef.headerName} iconType={null} />
      ),
      renderCell: p => (
        <DocumentCell
          annotationUrl={p.row.annotationUrl}
          value={p.value}
          // passing globals to document column due to the inability to have dynamic width of columns
          // based on number of icons, could be tackled by `autosizeOptions` in DataGrid v7 probably
          globals={p.row[globals]}
        />
      ),
    },
  ];
};
