import { SchemaItem } from '@rossum/api-client/schemas';
import {
  Divider,
  ListSubheader,
  MenuItem,
  MenuList,
  Stack,
} from '@rossum/ui/material';
import { UseQueryResult } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import ConditionalAuroraChip from '../../../components/conditional-aurora-chip/ConditionalAuroraChip';
import { schemaFieldRefPrefix } from '../FormulaEditor/constants';
import { useCurrentSchemaConceptContext } from '../FormulaPreview/contexts/currentSchema/currentSchemaContext';
import { OnInsertCallback, prependAuroraPrompt } from './insertion-helpers';
import MenuItemContentField from './MenuItemContentField';
import MenuItemContentFormula from './MenuItemContentFormula';
import MenuItemSkeleton from './MenuItemSkeleton';
import { menuListSharedProps, Template } from './shared';
import { useSearchInAll } from './useSearchInAll';

const MenuListAll = ({
  setFirstRef,
  filteredSchemaItems,
  filteredTemplates,
  onInsert,
  auroraSuggestionsQuery,
  debouncedSearch,
  search,
}: {
  search: string;
  debouncedSearch: string;
  onInsert: OnInsertCallback;
  setFirstRef: React.MutableRefObject<HTMLLIElement | null>;
  filteredSchemaItems: Array<SchemaItem>;
  filteredTemplates: Array<Template>;
  auroraSuggestionsQuery: UseQueryResult<Template[]>;
}) => {
  const intl = useIntl();
  const lists = useSearchInAll({
    search,
    debouncedSearch,
    filteredSchemaItems,
    filteredTemplates,
    auroraSuggestionsQuery,
  });
  const firstFocusableList = lists.find(
    list => list.items.length !== 0 && !list.loading
  );

  const { updateSchemaForPreview } = useCurrentSchemaConceptContext();

  return (
    <MenuList {...menuListSharedProps}>
      {lists
        .filter(list => list.loading || list.items.length !== 0)
        .map((list, listIndex) => {
          const { items, type } = list;

          if (list.loading) {
            return [
              <ListSubheader key={`${type}-loading-subheader`}>
                <Stack direction="row" gap={1} alignItems="center">
                  {intl.formatMessage({
                    id: `features.queueSettings.fields.formulaEditor.suggester.tabs.${type}`,
                  })}
                  {type === 'auroraSuggestions' && (
                    <ConditionalAuroraChip size="small" />
                  )}
                </Stack>
              </ListSubheader>,
              <MenuItemSkeleton key={`${type}-skeleton`} />,
            ];
          }

          return items.map((item, index) => {
            const ref =
              index === 0 && firstFocusableList?.type === type
                ? setFirstRef
                : undefined;

            const resolvedMenuItem =
              'id' in item ? (
                <MenuItem
                  key={item.id}
                  ref={ref}
                  onClick={() =>
                    onInsert(`${schemaFieldRefPrefix}.${item.id}`, {
                      insertMode: 'inline',
                    })
                  }
                >
                  <MenuItemContentField search={search} schemaField={item} />
                </MenuItem>
              ) : (
                <MenuItem
                  key={item.title}
                  ref={ref}
                  onClick={() => {
                    const snippet = item.auroraSuggestion
                      ? prependAuroraPrompt(item.snippet, search)
                      : item.snippet;
                    onInsert(snippet, { insertMode: 'new-block' });
                    if (item.auroraSuggestion) {
                      updateSchemaForPreview();
                    }
                  }}
                >
                  <MenuItemContentFormula search={search} template={item} />
                </MenuItem>
              );

            if (index === 0) {
              return [
                listIndex !== 0 ? (
                  <Divider sx={{ pt: 1 }} key={`${type}-divider`} />
                ) : null,
                <ListSubheader key={`${type}-subheader`}>
                  <Stack direction="row" gap={1} alignItems="center">
                    {intl.formatMessage({
                      id: `features.queueSettings.fields.formulaEditor.suggester.tabs.${type}`,
                    })}
                    {type === 'auroraSuggestions' && (
                      <ConditionalAuroraChip size="small" />
                    )}
                  </Stack>
                </ListSubheader>,
                resolvedMenuItem,
              ];
            }

            return resolvedMenuItem;
          });
        })}
    </MenuList>
  );
};
export default MenuListAll;
