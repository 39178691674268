// Generated by ts-to-zod
import { z } from 'zod';
import { annotationListTableSchema } from '../../shared/models/annotationListTable.schema';
import { automationSettingsSchema } from './automationSettings.schema';
import { autopilotConfigSchema } from './autopilotConfig.schema';
import { emailNotificationsSettingsSchema } from './emailNotificationsSettings.schema';
import { rejectionSettingsSchema } from './rejectionSettings.schema';
import { suggestedRecipientSourcesSettingsSchema } from './suggestedRecipientSourcesSettings.schema';

export const queueSettingsColumnsSchema = z.object({
  schemaId: z.string(),
});

export const queueSettingsUploadValuesSchema = z.object({
  id: z.string(),
  label: z.string(),
  required: z.boolean().optional(),
  type: z.string().optional(),
});

export const queueSettingsSchema = z
  .object({
    columns: z.array(queueSettingsColumnsSchema),
    hideExportButton: z.boolean(),
    autopilot: autopilotConfigSchema.nullable(),
    acceptedMimeTypes: z.array(z.string()),
    asynchronousExport: z.boolean(),
    automation: automationSettingsSchema,
    rejectionConfig: rejectionSettingsSchema,
    suggestedRecipientsSources: suggestedRecipientSourcesSettingsSchema,
    suggestedEdit: z.union([z.literal('suggest'), z.literal('disable')]),
    emailNotifications: emailNotificationsSettingsSchema,
    workflows: z
      .object({
        enabled: z.boolean(),
        bypassWorkflowsAllowed: z.boolean(),
      })
      .partial(),
    dashboardCustomization: z
      .object({
        allDocuments: z.boolean(),
      })
      .partial(),
    annotationListTable: annotationListTableSchema.optional(),
    uploadValues: z.unknown().optional(),
  })
  .partial();
