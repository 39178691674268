import { getIDFromUrl, Url } from '@rossum/api-client';
import {
  CustomEmailTemplateType,
  EmailTemplate,
} from '@rossum/api-client/emailTemplates';
import {
  Alert,
  alertClasses,
  AlertTitle,
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import CloseIcon from 'mdi-react/CloseIcon';
import { ReactNode, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useCreateEmailTemplate } from '../../../../features/emails/email-templates/hooks/useCreateEmailTemplate';
import { QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED } from '../../../../features/emails/email-templates/hooks/useEmailTemplatesPerQueueUnpaginated';
import { routerLink } from '../../../../lib/formaterValues';
import { isUserAdmin } from '../../../../redux/modules/user/selectors';
import { getSanitizedEditorMessage } from '../../../Queue/components/TemplateMessageEditor/hooks';
import { replaceSubjectVariable } from '../EmailResponseForm/defaultValues';
import NewTemplateNameModal from './NewTemplateNameModal';

type State =
  | 'sentWithModifiedTemplate'
  | 'sentWithUnmodifiedTemplate'
  | 'sentWithoutTemplate'
  | 'templateCreated';

type SentEmail = {
  message: string;
  subject: string;
  type: CustomEmailTemplateType;
  queueUrl: Url;
};

type Props = {
  sentEmail: SentEmail;
  emailTemplate?: EmailTemplate;
  parentEmail?: { subject?: string };
  onClose?: () => void;
  hideTemplateButton?: boolean;
};

type ContentProps = {
  button?: {
    label: string;
    callback: () => void;
  };
  title: ReactNode;
  subtitle?: ReactNode;
};

type ContentPropMapping = Record<State, ContentProps>;

const hasChanges = (
  sentEmail: SentEmail,
  emailTemplate: EmailTemplate
): boolean =>
  sentEmail.message !== getSanitizedEditorMessage(emailTemplate.message) ||
  sentEmail.subject !== emailTemplate.subject;

const getInitialState = (
  sentEmail: SentEmail,
  emailTemplate: EmailTemplate | undefined
): State => {
  if (!emailTemplate) {
    return 'sentWithoutTemplate';
  }

  const templateChanged = hasChanges(sentEmail, emailTemplate);
  if (!templateChanged) {
    return 'sentWithUnmodifiedTemplate';
  }

  // When you update a default template, it is treated as a new template
  // Because you should not be offered to update a default template.
  const isDefaultTemplate =
    emailTemplate.type === 'rejection_default' ||
    emailTemplate.type === 'email_with_no_processable_attachments';

  return isDefaultTemplate ? 'sentWithoutTemplate' : 'sentWithModifiedTemplate';
};

const EmailSentAlert = ({
  sentEmail,
  emailTemplate,
  parentEmail,
  hideTemplateButton,
  onClose,
}: Props) => {
  const intl = useIntl();

  const replaceSubject = <T extends { subject: string }>(object: T) => ({
    ...object,
    subject: replaceSubjectVariable(object.subject, parentEmail?.subject),
  });

  const [state, setState] = useState<State>(() =>
    getInitialState(
      replaceSubject(sentEmail),
      emailTemplate ? replaceSubject(emailTemplate) : undefined
    )
  );
  const [open, setOpen] = useState<boolean>(false);

  const { mutate: createEmailTemplate, isLoading: createIsLoading } =
    useCreateEmailTemplate();

  const queryClient = useQueryClient();
  const isLoading = createIsLoading;

  const queueId = getIDFromUrl(sentEmail.queueUrl);

  const linkToQueueSettings = intl.formatMessage(
    { id: 'components.emailSent.alert.subtitle.canEdit' },
    {
      link: routerLink({
        route: `/queues/${queueId}/settings/emails`,
        attributes: {
          style: {
            color: 'black',
            textDecoration: 'underline',
          },
        },
      }),
    }
  );

  const isAdmin = useSelector(isUserAdmin);

  const createButton =
    isAdmin && !hideTemplateButton
      ? {
          label: intl.formatMessage({
            id: 'components.emailSent.alert.button.save',
          }),
          callback: () => setOpen(true),
        }
      : undefined;

  const contentPropMapping: ContentPropMapping = {
    templateCreated: {
      title: intl.formatMessage({
        id: 'components.emailSent.alert.title.templateCreated',
      }),
      subtitle: linkToQueueSettings,
    },
    sentWithModifiedTemplate: {
      title: intl.formatMessage({
        id: 'components.emailSent.alert.title.emailSent',
      }),
      button: createButton,
    },
    sentWithUnmodifiedTemplate: {
      title: intl.formatMessage({
        id: 'components.emailSent.alert.title.emailSent',
      }),
    },
    sentWithoutTemplate: {
      title: intl.formatMessage({
        id: 'components.emailSent.alert.title.emailSent',
      }),
      button: createButton,
    },
  };

  const contentProps = contentPropMapping[state];

  const invalidateTemplates = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY_EMAIL_TEMPLATES_UNPAGINATED],
    });
  };

  const onModalSubmit = (name: string) => {
    setOpen(false);
    createEmailTemplate(
      {
        subject: sentEmail.subject,
        message: sentEmail.message,
        name,
        automate: false,
        type: sentEmail.type,
        queue: sentEmail.queueUrl,
        triggers: [],
        to: [],
        cc: [],
        bcc: [],
      },
      {
        onSuccess: () => {
          setState('templateCreated');
          invalidateTemplates();
        },
      }
    );
  };

  return (
    <>
      <Alert
        sx={{
          color: 'black',
          alignItems: 'center',
          [`.${alertClasses.action}`]: { pt: 0 },
        }}
        variant="filled"
        action={
          <Stack
            direction="row"
            spacing={1}
            sx={{ minWidth: 260 }}
            justifyContent="flex-end"
          >
            {contentProps.button && (
              <Button
                startIcon={
                  isLoading && <CircularProgress size={16} color="inherit" />
                }
                disabled={isLoading}
                disableElevation
                variant="contained"
                color="secondary"
                onClick={contentProps.button.callback}
              >
                {contentProps.button.label}
              </Button>
            )}
            {onClose && (
              <IconButton onClick={() => onClose()}>
                <CloseIcon />
              </IconButton>
            )}
          </Stack>
        }
      >
        {contentProps.title && (
          <AlertTitle sx={{ marginBottom: 0 }}>{contentProps.title}</AlertTitle>
        )}
        {contentProps.subtitle && (
          <Typography variant="body2">{contentProps.subtitle}</Typography>
        )}
      </Alert>
      <NewTemplateNameModal
        isOpen={open}
        defaultName={sentEmail.subject}
        onCancel={() => setOpen(false)}
        onSubmit={formModel => onModalSubmit(formModel.name)}
      />
    </>
  );
};

export default EmailSentAlert;
