import { Schema } from '@rossum/api-client/schemas';
import { Workspace } from '@rossum/api-client/workspaces';
import { Delete } from '@rossum/ui/icons';
import {
  Box,
  formControlClasses,
  LinearProgress,
  outlinedInputClasses,
  Stack,
} from '@rossum/ui/material';
import {
  DataGridPro,
  gridClasses,
  GridColDef,
  GridEventListener,
} from '@rossum/ui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useDataGridLocaleText } from '../../../document-list/hooks/useDataGridLocaleText';
import { Queue } from '../../types/queue';
import { FlatSchemaWithQueues } from '../../types/schema';
import { filterPanelProps } from '../ui/dataGridStyles';
import { Header } from '../ui/Header';
import AddFieldButtonWithDialog from './AddFieldButtonWithDialog';
import { getColumns } from './columns';
import { colorWarning, dataGridStyles } from './dataGridStyles';
import { useMemoOverviewFields } from './hooks/useMemoOverviewFields';
import { useStoredFilterModel } from './hooks/useStoredFilterModel';
import { DataForQueuesDialog, QueuesDialog } from './QueuesDialog';

type OverviewProps = {
  flatSchemasWithQueues: FlatSchemaWithQueues[];
  isLoading: boolean;
  workspaces: Workspace[];
  queues: Queue[];
  schemas: Schema[];
};

export const Overview = ({
  flatSchemasWithQueues,
  isLoading,
  workspaces,
  queues,
  schemas,
}: OverviewProps) => {
  const { push } = useHistory();
  const { pathname } = useLocation();
  const intl = useIntl();
  const localeText = useDataGridLocaleText();
  const [storedFilterModel, setFilterModel] = useStoredFilterModel();

  const [rowForQueuesDialog, setRowForQueuesDialog] =
    useState<DataForQueuesDialog | null>(null);

  const columns: GridColDef[] = useMemo(
    () =>
      getColumns({
        setRowForQueuesDialog,
        intl,
      }),
    [intl]
  );

  const fields = useMemoOverviewFields(flatSchemasWithQueues);

  const handleRowClick: GridEventListener<'rowClick'> = params =>
    push(`${pathname}/detail/${params.row.fieldId}`);

  return (
    <Stack spacing={2}>
      <Header>
        <AddFieldButtonWithDialog
          isLoading={isLoading}
          queues={queues}
          schemas={schemas}
          workspaces={workspaces}
        />
      </Header>
      <Box
        sx={{
          ...dataGridStyles,
          position: 'relative',
          height: '80vh',
        }}
      >
        <DataGridPro
          onRowClick={handleRowClick}
          disableColumnSelector
          hideFooter
          loading={isLoading}
          localeText={localeText}
          columns={columns}
          slots={{
            filterPanelDeleteIcon: Delete,
            loadingOverlay: LinearProgress,
          }}
          slotProps={{
            baseSelectOption: {
              // @ts-expect-error sx is not in the type definition but it works,
              sx: {
                // workaround for making first empty option having the same height as other options
                '&:after': { content: "' '" },
              },
            },
            baseFormControl: {
              sx: {
                // workaround for making singleSelect input filter full width
                [`> * .${formControlClasses.root}`]: {
                  width: '100%',
                },
              },
            },
            baseSelect: {
              native: false,
              sx: {
                [`.${gridClasses.cell} & .${outlinedInputClasses.notchedOutline}`]:
                  {
                    border: 'unset !important',
                  },

                [`& .${outlinedInputClasses.input}`]: {
                  pl: 2,
                },
              },
            },
            filterPanel: filterPanelProps,
          }}
          rows={fields}
          disableColumnReorder
          disableRowSelectionOnClick
          initialState={{ filter: { filterModel: storedFilterModel } }}
          onFilterModelChange={setFilterModel}
          sx={{
            border: 'none',
            cursor: 'pointer',
            '.MuiDataGrid-cell:focus': {
              outline: 'none',
            },

            '.MuiDataGrid-columnHeader:focus': {
              outline: 'none',
            },
          }}
          getCellClassName={params =>
            params.value &&
            Array.isArray(params.value) &&
            params.value.length > 1
              ? colorWarning
              : ''
          }
        />
      </Box>
      {rowForQueuesDialog !== null && (
        <QueuesDialog
          rowForQueuesDialog={rowForQueuesDialog}
          setRowForQueuesDialog={setRowForQueuesDialog}
        />
      )}
    </Stack>
  );
};
