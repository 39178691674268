import { endpoints } from '@rossum/api-client';
import { SuggestFormulaResponse } from '@rossum/api-client/internal';
import { SchemaSection } from '@rossum/api-client/schemas';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { api } from '../../../lib/apiClient';
import { Template } from './shared';

const narrowSuggestionResultsToTemplates = (
  data: SuggestFormulaResponse
): Array<Template> =>
  data.results.map(r => ({
    title: r.name,
    description: r.description,
    snippet: r.formula,
    example: '',
    auroraSuggestion: true,
  }));

type UseSuggestFormulaParams = {
  hint: string;
  schemaContent: SchemaSection[];
  fieldSchemaId: string;
};

export const useSuggestFormula = ({
  hint,
  schemaContent,
  fieldSchemaId,
}: UseSuggestFormulaParams) => {
  const queryClient = useQueryClient();

  const queryKey = [
    'formula-suggestions',
    fieldSchemaId,
    hint,
    schemaContent,
  ] as const;

  const auroraSuggestionsQuery = useQuery({
    queryKey,
    queryFn: () => {
      return api.request(
        endpoints.internal.schemas.suggestFormula({
          hint,
          schemaContent,
          fieldSchemaId,
        })
      );
    },
    select: narrowSuggestionResultsToTemplates,
    enabled: !!(hint.length && fieldSchemaId),
    cacheTime: 0, // clears data on closing popover suggester,
    onError: () => {
      // since we do not have whole schema content object in query key,
      // we need to invalidate it manually by setting results to empty array
      queryClient.setQueryData(queryKey, () => ({
        results: [],
      }));
    },
  });

  return auroraSuggestionsQuery;
};
