import {
  Checkbox,
  Chip,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from '@rossum/ui/material';
import { useRef, useState } from 'react';
import { filter, isString } from 'remeda';
import SearchInput from '../../../../ui/search-input/SearchInput';
import {
  doUnsavedValuesExist,
  getArrayValuesWithDefault,
  isEmptyValue,
} from '../helpers';
import { SimpleFilterComponentProps } from '../types';
import FilterActionsWrapper from './FilterActionsWrapper';
import { operatorWrapperStyles } from './Operators';
import { useOperators } from './Operators/useOperators';
import { resolveValueBasedOnBooleanOperator } from './Operators/utils';
import SelectionHandlers from './SelectionHandlers';

export const FilterMultiSelect = ({
  filterItem,
  onClose,
  onBackClick,
  applyFilter,
  convertToAdvancedFilter,
  onOperatorChange,
  isInAdvancedFilter,
}: SimpleFilterComponentProps) => {
  const {
    filterContext,
    column: { valueOptions, areValuesLoading },
  } = filterItem;

  const [searchValue, setSearchValue] = useState<string>('');
  const topSelections = useRef<string[]>(
    filter(getArrayValuesWithDefault(filterContext.value), isString)
  ).current;
  const [selectedValues, setSelectedValues] = useState<string[]>(topSelections);

  const handleSelect = (value: string) => {
    const doesSelectionExist = selectedValues.includes(value);
    const selection = doesSelectionExist
      ? selectedValues.filter(val => val !== value)
      : selectedValues.concat(value);

    setSelectedValues(selection);
  };

  const {
    node: Operators,
    operator,
    emptyOperatorIsUsed,
  } = useOperators({
    filterItem,
    onChange: onOperatorChange,
    value: selectedValues,
  });

  const advancedFilterIsDisabled = filterItem.column.operators.length === 1;

  const handleOnClickOnMore = convertToAdvancedFilter
    ? () =>
        convertToAdvancedFilter(
          operator,
          doUnsavedValuesExist(selectedValues, filterContext.value)
            ? selectedValues
            : undefined
        )
    : undefined;

  const topValueOptions =
    valueOptions?.filter(({ value }) => topSelections.includes(value)) ?? [];

  const valueOptionsWithoutTopSelections =
    valueOptions?.filter(({ value }) => !topSelections.includes(value)) ?? [];

  const formattedOptions = topValueOptions.concat(
    valueOptionsWithoutTopSelections
  );

  return (
    <FilterActionsWrapper
      onSubmit={() => {
        applyFilter({
          operator,
          value: emptyOperatorIsUsed
            ? resolveValueBasedOnBooleanOperator(operator, selectedValues)
            : selectedValues,
        });
      }}
      onClickOnMore={advancedFilterIsDisabled ? undefined : handleOnClickOnMore}
      onBackClick={onBackClick}
      isSubmitDisabled={!emptyOperatorIsUsed && isEmptyValue(selectedValues)}
      onCancel={onClose}
      columnField={filterItem.column.field}
      disableOnMoreClick={areValuesLoading}
    >
      <Stack spacing={1} width="100%">
        {!emptyOperatorIsUsed ? (
          <>
            <Stack sx={operatorWrapperStyles}>
              {!isInAdvancedFilter ? <Operators /> : null}
              {formattedOptions.length ? (
                <SelectionHandlers
                  selectedValues={selectedValues}
                  valueOptions={formattedOptions.map(({ value }) => value)}
                  setValues={setSelectedValues}
                />
              ) : null}
            </Stack>

            <SearchInput
              onChange={setSearchValue}
              value={searchValue}
              withStartAdornment={false}
              sx={{ width: '100%' }}
            />
            {areValuesLoading ? (
              <Stack spacing={0.3} width="100%">
                {[...Array(5)].map((_, index) => (
                  <Stack
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    flexDirection="row"
                    alignItems="center"
                    gap={2}
                  >
                    <Checkbox disabled sx={{ p: 0 }} />
                    <Skeleton height={30} width="100%" variant="text" />
                  </Stack>
                ))}
              </Stack>
            ) : (
              <List
                sx={{
                  maxHeight: 'max(300px,40vh)',
                  overflow: 'auto',
                }}
              >
                {topValueOptions
                  .concat(valueOptionsWithoutTopSelections)
                  .filter(
                    ({ label }) =>
                      !searchValue ||
                      label.toLowerCase().includes(searchValue.toLowerCase())
                  )
                  .map(({ value, label, count }) => (
                    <ListItem key={value} disablePadding disableGutters dense>
                      <ListItemButton
                        onClick={() => handleSelect(value)}
                        disableRipple
                      >
                        <ListItemIcon sx={{ minWidth: 0, mr: 2 }}>
                          <Checkbox
                            checked={selectedValues.includes(value)}
                            sx={{ p: 0 }}
                          />
                        </ListItemIcon>
                        <ListItemText sx={{ m: 0 }}>
                          <Typography
                            variant="body2"
                            sx={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                          >
                            {label}
                          </Typography>
                        </ListItemText>

                        {typeof count === 'number' && (
                          <Chip size="tiny" label={count} />
                        )}
                      </ListItemButton>
                    </ListItem>
                  ))}
              </List>
            )}
          </>
        ) : (
          <Operators />
        )}
      </Stack>
    </FilterActionsWrapper>
  );
};
