import { Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import CloseIcon from 'mdi-react/CloseIcon';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DISPLAYED_VALUE_CLASSNAME } from '../../../constants/values';
import {
  deleteDatapoint,
  selectDatapoint,
} from '../../../redux/modules/datapoints/actions';
import { SimpleDatapointDataST } from '../../../types/datapoints';
import { UiFieldType } from '../../../types/schema';
import DatapointTooltip, {
  getTooltipTypeWithHighestPriority,
} from '../../DatapointTooltip';
import styles from '../styles.module.sass';

type Props = {
  disableDelete: boolean;
  documentAutomated: boolean;
  id: number;
  parentPath: Array<number>;
  readOnly: boolean;
  value: string | null | undefined;
  tickIconColor: null | string;
  datapointData: SimpleDatapointDataST;
  displayAutomationBlockers: boolean;
  active: boolean;
  handleBatchSelection: (params: BatchSelectionParams) => void;
  isAlreadyIncluded: boolean;
  isSelectable: boolean;
  uiFieldType: UiFieldType | undefined;
};

export type BatchSelectionParams =
  | {
      type: 'oneByOne';
      isAlreadyIncluded: boolean;
      datapointId: number;
    }
  | {
      type: 'multiple';
      datapointId: number;
    }
  | {
      type: 'forcedContent';
      payload: Array<number>;
    };

const Value = ({
  disableDelete,
  documentAutomated,
  id,
  parentPath,
  readOnly,
  value,
  tickIconColor,
  datapointData,
  displayAutomationBlockers,
  active,
  handleBatchSelection,
  isAlreadyIncluded,
  isSelectable,
  uiFieldType,
}: Props) => {
  const dispatch = useDispatch();
  const tooltipType = getTooltipTypeWithHighestPriority({
    displayAutomationBlockers,
    tickIconColor,
    uiFieldType,
  });

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.ctrlKey || event.metaKey) {
      // META(COMMAND on MAC) and CTRL keys on rest of OS - add dataPoints one by one
      handleBatchSelection({
        type: 'oneByOne',
        datapointId: id,
        isAlreadyIncluded,
      });
    } else if (event.shiftKey) {
      // SHIFT key - add multiple dataPoints in GOOGLE sheet style
      handleBatchSelection({
        type: 'multiple',
        datapointId: id,
      });
    } else {
      handleBatchSelection({
        type: 'forcedContent',
        payload: [],
      });
      // simple select dataPoints
      dispatch(selectDatapoint([...parentPath, id]));
    }
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      key={id}
      className={clsx(
        styles.Item,
        styles.ValueText,
        !tickIconColor && styles.UnvalidatedValue,
        isAlreadyIncluded && styles.ValueTextBatchSelected,
        isSelectable
          ? styles.ValueContentEditable
          : styles.ValueContentNotEditable
      )}
      onClick={event => {
        event.stopPropagation();
        if (isSelectable) {
          handleClick(event);
        }
      }}
    >
      {tooltipType ? (
        <DatapointTooltip
          data={datapointData}
          tickIconColor={tickIconColor}
          active={active}
          documentAutomated={documentAutomated}
          tooltipType={tooltipType}
        />
      ) : (
        <div className={styles.TickIconPlaceholder} />
      )}
      <span
        className={clsx(
          styles.ValueContent,
          tickIconColor === 'white' &&
            styles[`ColorByValidationSource-${tickIconColor}`],
          isAlreadyIncluded && styles.TextBatchSelected,

          DISPLAYED_VALUE_CLASSNAME
        )}
      >
        {value}
      </span>
      {!readOnly && isSelectable && (
        <CloseIcon
          onClick={e => {
            e.stopPropagation();
            if (!disableDelete) {
              dispatch(deleteDatapoint([...parentPath, id]));
            }
          }}
          className={clsx(
            styles.CloseIcon,
            disableDelete && styles.DisabledCross
          )}
        />
      )}
    </Stack>
  );
};

export default Value;
