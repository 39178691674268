import { Workspace } from '@rossum/api-client/workspaces';
import { LicenseInfo } from '@rossum/ui/x-license-pro';
import { useIsFetching } from '@tanstack/react-query';
import { uniq } from 'lodash';
import { useMemo } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import FieldManagerSurvey from '../../../components/DataCaptureSurvey/FieldManagerSurvey';
import { muiDataGridLicenseKey } from '../../../constants/config';
import { useGetQueues } from '../hooks/useGetQueues';
import { useGetSchemas } from '../hooks/useGetSchemas';
import { useGetWorkspaces } from '../hooks/useGetWorkspaces';
import { useMemoFlatSchemasWithQueues } from '../hooks/useMemoFlatSchemasWithQueues';
import { CreateFieldForm } from './create-field/CreateFieldForm';
import { FieldDetail } from './field-detail/FieldDetail';
import { Overview } from './overview/Overview';
import { isNotNullOrUndefined } from './typeguards';

LicenseInfo.setLicenseKey(muiDataGridLicenseKey);

type OwnProps = RouteComponentProps<{ section: string }>;

export type FieldManagerProps = OwnProps;

export const FieldManager = ({ match: { path } }: FieldManagerProps) => {
  const queuesQuery = useGetQueues();

  const schemaUrls = useMemo(() => {
    if (!queuesQuery.isFetching && !queuesQuery.hasNextPage) {
      return uniq(
        queuesQuery.data?.pages.flatMap(page =>
          page.results.map(result => result.schema)
        )
      );
    }
    return [];
  }, [
    queuesQuery.isFetching,
    queuesQuery.hasNextPage,
    queuesQuery.data?.pages,
  ]);

  const schemaQueries = useGetSchemas(schemaUrls);

  const queues = useMemo(
    () => queuesQuery.data?.pages.flatMap(page => page.results) || [],
    [queuesQuery.data]
  );

  const schemaQueriesLoadedTimestamp = schemaQueries.some(
    query => query.isFetching
  )
    ? 0
    : schemaQueries.reduce((acc, { dataUpdatedAt }) => acc + dataUpdatedAt, 0);

  const schemas = useMemo(
    () => schemaQueries.map(({ data }) => data).filter(isNotNullOrUndefined),
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [schemaQueriesLoadedTimestamp]
  );

  const workspaceQueries = useGetWorkspaces();

  const workspaces: Workspace[] = useMemo(
    () => workspaceQueries.data?.pages.flatMap(page => page.results) || [],
    [workspaceQueries.data]
  );

  const flatSchemasWithQueues = useMemoFlatSchemasWithQueues(queues, schemas);

  const queuesAreLoading = !!useIsFetching({
    queryKey: ['queues-paginated'],
  });

  const workspacesAreLoading = !!useIsFetching({
    queryKey: ['workspaces-paginated'],
  });

  const schemasAreLoading = !!useIsFetching({
    queryKey: ['schema'],
  });

  const gridIsLoading =
    queuesAreLoading || workspacesAreLoading || schemasAreLoading;

  return (
    <>
      <Switch>
        <Route exact path={`${path}/detail/:schemaId`}>
          <FieldDetail
            flatSchemasWithQueues={flatSchemasWithQueues}
            schemas={schemas}
            queues={queues}
            workspaces={workspaces}
            isLoading={gridIsLoading}
          />
        </Route>
        <Route
          exact
          path={`${path}/schema/:schemaId/parent/:parentId/create-field`}
        >
          <CreateFieldForm schemas={schemas} rootPath={path} />
        </Route>
        <Route exact path={`${path}`}>
          <Overview
            flatSchemasWithQueues={flatSchemasWithQueues}
            queues={queues}
            schemas={schemas}
            workspaces={workspaces}
            isLoading={gridIsLoading}
          />
        </Route>
      </Switch>
      <FieldManagerSurvey allDataLoaded={!gridIsLoading} />
    </>
  );
};
