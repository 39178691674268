import { getIDFromUrl, ID } from '@rossum/api-client';
import {
  AnnotationListSideload,
  SideloadOptions,
} from '@rossum/api-client/annotations';
import { Queue, QueueCounts } from '@rossum/api-client/queues';
import { MetaField, SchemaColumn } from '@rossum/api-client/shared';
import { User } from '@rossum/api-client/users';
import { Workspace } from '@rossum/api-client/workspaces';
import { fromEntries, groupBy, indexBy, uniqueBy } from 'remeda';
import { snakeToCamel } from '../../lib/keyConvertor';
import { getAnnotationId } from '../../lib/url';
import { SortingValue } from '../../types/user';
import { resolveAnnotationEdits } from '../document-edit/helpers';
import { resolveAnnotationDuplicates } from '../duplicates/helpers';
import {
  DashboardAnnotationListWithSideload,
  getColumnField,
  resolveModifierFromUrl,
} from './columns/helpers';
import { LevelOptions } from './hooks/useDashboardQuery';
import { allowedPageSizes } from './hooks/usePagination';

type CompareFn<T> = (a: T, b: T) => number;

export type WorkspaceWithQueues = Omit<Workspace, 'queues'> & {
  queues: Queue[];
};

export type WorkspaceWithToReviewCount = WorkspaceWithQueues & {
  toReviewCount: number;
};

export type ResponseWithDicts = ReturnType<
  typeof transformSideloadedListsToDicts
>;

type SortFns<T> = Record<SortingValue, CompareFn<T>>;

const sortFns: Omit<SortFns<{ id: number; name: string }>, 'toReview'> = {
  oldest: (a, b) => a.id - b.id,
  newest: (a, b) => b.id - a.id,
  alphabeticalDesc: (a, b) => b.name.localeCompare(a.name),
  alphabeticalAsc: (a, b) => a.name.localeCompare(b.name),
};

export const queueSortFns: SortFns<{
  counts: QueueCounts;
  id: number;
  name: string;
}> = {
  ...sortFns,
  toReview: (valueA, valueB) => {
    const valueAToReview = valueA.counts.to_review ?? 0;
    const valueAReviewing = valueA.counts.reviewing ?? 0;

    const valueBToReview = valueB.counts.to_review ?? 0;
    const valueBReviewing = valueB.counts.reviewing ?? 0;
    return (
      valueBToReview + valueBReviewing - (valueAToReview + valueAReviewing)
    );
  },
};

export const workspaceSortFns: SortFns<{
  id: number;
  name: string;
  toReviewCount: number | undefined;
}> = {
  ...sortFns,
  toReview: (valueA, valueB) => {
    return (valueB.toReviewCount ?? 0) - (valueA.toReviewCount ?? 0);
  },
};

export const toDict = <T extends { url: string }>(
  items: T[] = [],
  keyProp: keyof T = 'url'
) => indexBy(items, item => item[keyProp]);

const groupContentByAnnotationId = (
  columns: AnnotationListSideload['content'] | undefined
) => {
  return groupBy(columns ?? [], datapoint =>
    getAnnotationId(datapoint.url ?? '')
  );
};

type ConvertKeysToDictionary<T, Keys extends keyof T> = {
  [K in keyof T]: K extends Keys
    ? NonNullable<T[K]> extends Array<infer Item>
      ? Record<string, Item>
      : T[K]
    : T[K];
};

export const transformSideloadedListsToDicts = (
  response: DashboardAnnotationListWithSideload
): ConvertKeysToDictionary<
  DashboardAnnotationListWithSideload,
  | 'modifiers'
  | 'confirmedBys'
  | 'documents'
  | 'assignees'
  | 'labels'
  | 'exportedBys'
  | 'rejectedBys'
  | 'deletedBys'
> => ({
  ...response,
  modifiers: toDict(response.modifiers),
  documents: toDict(response.documents),
  labels: toDict(response.labels),
  assignees: toDict(response.assignees),
  exportedBys: toDict(response.exportedBys),
  confirmedBys: toDict(response.confirmedBys),
  rejectedBys: toDict(response.rejectedBys),
  deletedBys: toDict(response.deletedBys),
});

export type TransformedData = ReturnType<
  typeof transformDataResultsToRows
>['results'][number];

export const transformDataResultsToRows = (
  data: ResponseWithDicts,
  statusSet: Set<string>,
  queueSet: Set<number>
) => ({
  ...data,
  results: data.results.map(annotation => {
    const duplicatesUrls = resolveAnnotationDuplicates(
      annotation.url,
      annotation.relations,
      data.relations
    );
    const edit = resolveAnnotationEdits(annotation.url, data.relations);

    const attachmentRelationsList = uniqueBy(
      [...data.relations, ...data.childRelations].filter(
        relation => relation.type === 'attachment'
      ),
      rel => rel.id
    );

    const hasAttachments = attachmentRelationsList.some(
      relation =>
        relation.parent === annotation.url ||
        relation.annotations.includes(annotation.url)
    );

    const getModifierData = (
      url: string | null,
      sideloadedUsers: Record<string, User> | undefined
    ) => resolveModifierFromUrl(url, sideloadedUsers ?? {});

    return {
      ...annotation,
      restricted_access: annotation.restrictedAccess,
      isOutdated:
        annotation.status === 'purged' ||
        (statusSet.size === 0 ? false : !statusSet.has(annotation.status)) ||
        (queueSet.size === 0
          ? false
          : !queueSet.has(getIDFromUrl(annotation.queue))),
      duplicates: duplicatesUrls.map(duplicateUrl =>
        data.relations.find(relation => relation.url === duplicateUrl)
      ),
      edit,
      hasAttachments,
      status: snakeToCamel(annotation.status),
      original_file_name: data.documents[annotation.document]?.originalFileName,
      created_at: annotation.createdAt,
      modified_at: annotation.modifiedAt,
      modifier: getModifierData(annotation.modifier, data.modifiers),
      exported_at: annotation.exportedAt,
      exported_by: getModifierData(annotation.exportedBy, data.exportedBys),
      confirmed_at: annotation.confirmedAt,
      confirmed_by: getModifierData(annotation.confirmedBy, data.confirmedBys),
      rejected_at: annotation.rejectedAt,
      rejected_by: getModifierData(annotation.rejectedBy, data.rejectedBys),
      deleted_at: annotation.deletedAt,
      deleted_by: getModifierData(annotation.deletedBy, data.deletedBys),
      assigned_at: annotation.assignedAt,
      assignees: annotation.assignees.map(url =>
        getModifierData(url, data.assignees)
      ),
    } satisfies Record<
      | Exclude<MetaField, 'details' | 'actions'>
      | 'duplicates'
      | 'edit'
      | 'hasAttachments'
      | 'isOutdated'
      | 'restricted_access',
      unknown
    >;
  }),
});

export const appendSchemaColumns = (
  annotation: TransformedData,
  rawSchemaColumns: SchemaColumn[],
  sideloadContent: AnnotationListSideload['content'],
  loadedAnnotationIds: number[]
) => {
  const content = groupContentByAnnotationId(sideloadContent);
  const schemaColumnsDataTypes = fromEntries(
    rawSchemaColumns.map(col => [col.schemaId, col.dataType])
  );

  const schemaColumns = fromEntries(
    (content[annotation.id] || []).map(datapoint => [
      getColumnField({
        columnType: 'schema',
        field: datapoint.schemaId ?? 'uknown schema id',
        dataType: datapoint.schemaId
          ? schemaColumnsDataTypes[datapoint.schemaId]
          : undefined,
      }),
      {
        annotationId: annotation.id,
        datapointId: datapoint.id,
        value: datapoint.content?.value,
        validationSources: datapoint.validationSources,
        confidenceScore: datapoint.content?.rirConfidence,
      },
    ])
  );

  return {
    ...annotation,
    ...schemaColumns,
    datapointsReady: loadedAnnotationIds.includes(annotation.id),
  };
};

export const transformResponse = (
  response: DashboardAnnotationListWithSideload,
  statusSet: Set<string>,
  queueSet: Set<number>
) =>
  transformDataResultsToRows(
    transformSideloadedListsToDicts(response),
    statusSet,
    queueSet
  );

export const getActiveQueue = ({
  queues,
  queueId,
  level,
}: {
  queues: Queue[] | undefined;
  queueId: ID | undefined;
  level: LevelOptions | Omit<string, LevelOptions> | undefined;
}): Queue | null => {
  if (level !== 'queue' || !queues || !queueId) return null;

  return queues.find(q => q.id === queueId) ?? null;
};

export const getPageSizeFromQuery = <T extends { page_size?: string }>({
  query,
  fallbackValue,
}: {
  query: T;
  fallbackValue: number;
}) =>
  allowedPageSizes.find(ps => ps.toString() === query?.page_size) ??
  fallbackValue;

export type SideloadValue = Partial<Record<SideloadOptions, true>>;

const sideloadMap: Partial<Record<MetaField, SideloadValue>> = {
  assignees: { assignees: true },
  modifier: { modifiers: true },
  exported_by: { exportedBys: true },
  confirmed_by: { confirmedBys: true },
  deleted_by: { deletedBys: true },
  rejected_by: { rejectedBys: true },
};

export const resolveUserSideloadsByColumn = ({
  columnNames,
}: {
  columnNames: MetaField[];
}): SideloadValue => {
  return columnNames.reduce((acc, columnName) => {
    const sideloadValue = sideloadMap[columnName];
    if (sideloadValue) return { ...acc, ...sideloadValue };

    return acc;
  }, {});
};
