import { endpoints, withSideload } from '@rossum/api-client';
import { AnnotationsSearchQuery } from '@rossum/api-client/annotations';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { FETCH_INTERVAL } from '../../../constants/values';
import { api } from '../../../lib/apiClient';
import { organizationGroupSelector } from '../../../redux/modules/organizationGroup/selectors';
import { useLabelsEnabled } from '../../labels/hooks/useLabelsEnabled';
import { DashboardAnnotationListWithSideload } from '../columns/helpers';
import { DataGridConditionModel, encodeMqlQuery } from '../filtering/mql';
import { MetaColDef } from '../filtering/types';
import { resolveUserSideloadsByColumn, transformResponse } from '../helpers';
import { LevelOptions } from './useDashboardQuery';

export const ALL_DOCUMENTS_QUERY_KEY = 'all-documents';

const DEFAULT_ORDERING = '-created_at';

export const useFetchDashboardData = ({
  query,
  searchQuery,
  dataGridFiltering,
  isEnabled,
  isTableConfigLoading,
  statusColumns,
}: {
  statusColumns: MetaColDef[];
  isTableConfigLoading: boolean;
  query: AnnotationsSearchQuery;
  searchQuery: string | undefined;
  dataGridFiltering: DataGridConditionModel;
  isEnabled: boolean;
  level: LevelOptions | Omit<string, LevelOptions> | undefined;
}) => {
  const orgGroupLoaded = !!useSelector(organizationGroupSelector);

  const userSideloadConfig = resolveUserSideloadsByColumn({
    columnNames: statusColumns.filter(c => c.visible).map(c => c.metaName),
  });

  const resolvedQuery = {
    ...query,
    ordering: query.ordering || DEFAULT_ORDERING,
  };

  const labelsEnabled = useLabelsEnabled();
  const mqlQuery = encodeMqlQuery(dataGridFiltering);

  return useQuery(
    [
      ALL_DOCUMENTS_QUERY_KEY,
      resolvedQuery,
      mqlQuery,
      searchQuery,
      userSideloadConfig,
    ] as const,
    (): Promise<DashboardAnnotationListWithSideload> =>
      api.request(
        withSideload(
          endpoints.annotations.search(resolvedQuery, {
            query: mqlQuery,
            ...(searchQuery && { queryString: { string: searchQuery } }),
          }),
          {
            relations: true,
            documents: true,
            childRelations: true,
            labels: labelsEnabled || undefined,
            ...userSideloadConfig,
          }
        )
      ),
    {
      enabled: orgGroupLoaded && isEnabled && !isTableConfigLoading,
      refetchInterval: data => {
        return data?.results.some(result => result.isOutdated)
          ? 3000
          : FETCH_INTERVAL;
      },
      retry: (_, error) => !error,
      select: useCallback(
        (response: DashboardAnnotationListWithSideload) => {
          const filteredStatuses: Array<string> =
            dataGridFiltering?.items.find(
              i => i.field === 'status' && i.operator === 'isAnyOf'
            )?.value || [];

          const filteredQueues: Array<number> = (
            dataGridFiltering?.items.find(
              i => i.field === 'queue' && i.operator === 'isAnyOf'
            )?.value || []
          ).flatMap((value: string) => parseInt(value, 10) || []);

          const queueSet = new Set(filteredQueues);
          const statusSet = new Set(filteredStatuses);

          return transformResponse(response, statusSet, queueSet);
        },
        [dataGridFiltering?.items]
      ),
    }
  );
};

export const useSetDashboardData = () => {
  const queryClient = useQueryClient();

  const setDashboardData = (
    callback: (
      response: DashboardAnnotationListWithSideload | undefined
    ) => DashboardAnnotationListWithSideload | undefined
  ) =>
    queryClient.setQueriesData<DashboardAnnotationListWithSideload | undefined>(
      [ALL_DOCUMENTS_QUERY_KEY],
      response => callback(response)
    );

  return setDashboardData;
};
