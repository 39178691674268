import { ChevronRight } from '@rossum/ui/icons';
import { Breadcrumbs } from '@rossum/ui/material';
import { useIntl } from 'react-intl';
import { Breadcrumb } from '../../ui/breadcrumb/Breadcrumb';
import { automationPath } from './helpers';

type Breadcrumb = {
  label: string;
  to?: string;
};

type AutomationBreadcrumbsProps = {
  breadcrumbs: Breadcrumb[];
};

export const AutomationBreadcrumbs = ({
  breadcrumbs,
}: AutomationBreadcrumbsProps) => {
  const intl = useIntl();

  const automationBreadcrumbs = [
    {
      to: automationPath(),
      label: intl.formatMessage({ id: 'components.appBar.automation' }),
    },
    ...breadcrumbs,
  ];

  return (
    <Breadcrumbs separator={<ChevronRight fontSize="small" color="disabled" />}>
      {automationBreadcrumbs.map(({ label, to }) => (
        <Breadcrumb key={label} to={to}>
          {label}
        </Breadcrumb>
      ))}
    </Breadcrumbs>
  );
};
