import { Stack } from '@rossum/ui/material';
import clsx from 'clsx';
import equal from 'fast-deep-equal/es6/react';
import CloseIcon from 'mdi-react/CloseIcon';
import { LegacyRef } from 'react';
import { useDispatch } from 'react-redux';
import { deleteDatapointAndNavigate } from '../../../redux/modules/datapoints/actions';
import {
  DatapointValueDataST,
  SimpleDatapointDataST,
} from '../../../types/datapoints';
import { AnyDatapointSchema, UiFieldType } from '../../../types/schema';
import Input from '../../Datapoint/components/Input';
import DatapointTooltip, {
  getTooltipTypeWithHighestPriority,
} from '../../DatapointTooltip';
import styles from '../styles.module.sass';

type Props = {
  data: DatapointValueDataST;
  datapointPath: Array<number>;
  disableDelete: boolean;
  documentAutomated: boolean;
  editingDatapointValue: boolean;
  inFooter: boolean;
  myPath: Array<number>;
  setRef: LegacyRef<HTMLDivElement>;
  onChange: (_val: string) => void;
  readOnly: boolean;
  schemaId: string;
  tickIconColor: null | 'gray' | 'green' | 'white';
  datapointData: SimpleDatapointDataST;
  displayAutomationBlockers: boolean;
  id?: string;
  isEditable: boolean;
  uiFieldType: UiFieldType | undefined;
  schema: AnyDatapointSchema | undefined;
  isSelectable: boolean;
};

const ValueInput = ({
  data,
  datapointPath,
  disableDelete,
  documentAutomated,
  editingDatapointValue,
  inFooter,
  myPath,
  setRef,
  onChange,
  readOnly,
  schemaId,
  tickIconColor,
  datapointData,
  displayAutomationBlockers,
  id,
  isEditable,
  uiFieldType,
  schema,
  isSelectable,
}: Props) => {
  const dispatch = useDispatch();

  const active = equal(myPath, datapointPath);
  const tooltipType = getTooltipTypeWithHighestPriority({
    displayAutomationBlockers,
    tickIconColor,
    uiFieldType,
  });

  return (
    // @ts-expect-error Error due to wrong ref types all across Datapoint component
    <Stack
      direction="row"
      spacing={1}
      ref={setRef}
      className={clsx(styles.Item, styles.InputValue)}
      id={id}
    >
      {tooltipType === 'validationSources' ? (
        <DatapointTooltip
          data={datapointData}
          tickIconColor={tickIconColor}
          documentAutomated={documentAutomated}
          tooltipType={tooltipType}
        />
      ) : (
        <div className={styles.TickIconPlaceholder} />
      )}
      <Input
        displayAutomationBlockers={displayAutomationBlockers}
        active={active}
        data={data}
        editingDatapointValue={editingDatapointValue}
        documentAutomated={documentAutomated}
        inFooter={inFooter}
        onChange={onChange}
        readOnly={readOnly}
        columnSchemaId={schemaId}
        tickIconColor={tickIconColor}
        isEditable={isEditable}
        schema={schema}
      />
      {!readOnly && isSelectable && (
        <CloseIcon
          onClick={e => {
            e.stopPropagation();
            dispatch(deleteDatapointAndNavigate(myPath));
          }}
          className={clsx(
            styles.CloseIconActive,
            disableDelete && styles.DisabledCross
          )}
        />
      )}
    </Stack>
  );
};

export default ValueInput;
