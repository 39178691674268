import { useEffect, useMemo, useState } from 'react';
import { SurveyName } from '../../business/surveys/surveysUuidMap';
import { DEV_FEATURES_ENABLED } from '../../constants/config';
import { DevToolsSync } from './DevToolsSync';
import { FieldsSurveyDialog } from './dialogs/FieldsSurveyDialog';
import { useSurveyIfEnabled } from './hooks/useSurveyIfEnabled';

const SURVEY_NAME = 'formulaFieldsSurvey' satisfies SurveyName;

type FormulaFieldsSurveyProps = {
  children: (props: { setFocused: (focused: boolean) => void }) => JSX.Element;
};

const FormulaFieldsSurvey = ({ children }: FormulaFieldsSurveyProps) => {
  const [focused, setFocused] = useState(false);
  const [userEngaged, setUserEngaged] = useState(false);

  const surveyInfo = useSurveyIfEnabled({
    surveyName: SURVEY_NAME,
    userEngaged,
  });

  // we have sufficient engagement for survey feedback after 10s spent
  // w/ this component rendered and focused
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (focused) {
        setUserEngaged(true);
      }
    }, 10 * 1000);
    return () => {
      clearTimeout(timeoutId);
    };
  }, [focused]);

  // For debugging.
  const surveyDevToolsAction = useMemo(
    () => ({
      type: `@@ELIS_DEVTOOLS/SET_SURVEY_STATE` as const,
      payload: [
        {
          label: 'Formula Fields Survey',
          ...surveyInfo,
        },
      ],
    }),
    [surveyInfo]
  );

  return (
    <>
      {children({ setFocused })}
      {DEV_FEATURES_ENABLED && <DevToolsSync action={surveyDevToolsAction} />}
      {surveyInfo.survey && focused && (
        <FieldsSurveyDialog
          open={surveyInfo.shouldViewSurvey}
          survey={surveyInfo.survey}
          viewBlockers={surveyInfo.viewBlockers}
          surveyName={SURVEY_NAME}
        />
      )}
    </>
  );
};

export default FormulaFieldsSurvey;
