const surveys = [
  'dataCaptureSurvey',
  'auroraForLineItemsSurvey',
  'fieldsToCaptureRedesignSurvey',
  'formulaFieldsSurvey',
  'fieldManagerSurvey',
] as const;

export type SurveyName = (typeof surveys)[number];

export const surveysUuidMap: Record<SurveyName, string> = {
  dataCaptureSurvey: '1e90b867-8bcd-4fce-be37-9b3b0fa6dacd',
  auroraForLineItemsSurvey: '533d578c-8432-46fe-8117-d07baf6f68bc',
  fieldsToCaptureRedesignSurvey: 'd549aa88-9b5b-4e4a-9906-74e11435de63',
  formulaFieldsSurvey: 'c71ee934-543b-4605-95c3-0bd5e3f257ff',
  fieldManagerSurvey: '1214d95c-9259-4378-8ab1-1e6f6352080a',

  // not used anymore
  // unifiedDashboardSurvey: '36b5b99e-d1ba-432c-967e-87e9732695d4',
};
