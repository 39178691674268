import { OrganizationGroup } from '../../../types/organizationGroup';
import { State } from '../../../types/state';

export const organizationGroupSelector = (
  state: State
): OrganizationGroup | undefined => state.organizationGroup?.current;

export const approvalWorkflowsEnabledSelector = (state: State) =>
  state.organizationGroup.current?.features?.approvalWorkflows?.enabled;

export const usageReportsEnabledSelector = (state: State) =>
  state.organizationGroup.current?.features?.usageReports?.enabled;

export const emailChatCompletionsEnabledSelector = (state: State) =>
  state.organizationGroup.current?.features?.emailChatCompletions?.enabled;

export const formulaFieldsEnabledSelector = (state: State) =>
  !!state.organizationGroup.current?.features?.formulaFields?.enabled;

export const auroraEngineConfigEnabledSelector = (state: State) =>
  !!state.organizationGroup.current?.features?.auroraEngineConfig?.enabled;

export const restrictedEngineCreationEnabledSelector = (state: State) =>
  !!state.organizationGroup.current?.features?.restrictedEngineCreation
    ?.enabled;

export const restrictedEngineEditEnabledSelector = (state: State) =>
  !!state.organizationGroup.current?.features?.restrictedEngineEdit?.enabled;

export const complexTablesEnabledSelector = (state: State) =>
  // The "?" after organizationGroup is here only because of unit tests
  // The organizationGroup could be mocked in the tests but we are
  // using this selector also in epics and I didn't want to mock the orgGroup everywhere
  // TODO: might be good to redo it a little bit
  !!state.organizationGroup?.current?.features?.complexTables?.enabled;

export const auroraEngineEnabledSelector = (state: State) =>
  !!state.organizationGroup.current?.features?.auroraEngine?.enabled;

export const uploadAdditionalValuesSelector = (state: State) =>
  !!state.organizationGroup.current?.features?.uploadAdditionalValues?.enabled;
