import { Survey } from '@rossum/api-client/surveys';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { usePatchSurvey } from '../../../business/surveys/usePatchSurvey';
import { QUERY_KEY_SURVEY } from '../../../business/surveys/useSurvey';
import { getUnsafe } from '../../../lib/helpers';

function getNumber(value: unknown): number {
  const num = Number(value);
  return Number.isNaN(num) ? 0 : num;
}

export const useDataCaptureSurveyActions = (
  survey: Survey,
  additionalData: Record<string, unknown>
) => {
  const queryClient = useQueryClient();

  const { mutateAsync: patchSurveyAsync } = usePatchSurvey();

  return useMemo(() => {
    const patchSurvey = async (
      variables: Parameters<typeof patchSurveyAsync>[0]
    ) => {
      return patchSurveyAsync(variables).then(survey =>
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY_SURVEY, survey.id],
        })
      );
    };

    return {
      /** Used for counting how many times survey has been viewed */
      increaseViewCount: () =>
        patchSurvey({
          surveyId: survey.id,
          surveyPatchModel: {
            additionalData: {
              ...(typeof survey.additionalData === 'object'
                ? survey.additionalData
                : {}),
              ...additionalData,
              viewCount:
                getNumber(getUnsafe(survey.additionalData, 'viewCount')) + 1,
            },
          },
        }),
      /** Used for counting how many times survey has been skipped  */
      increaseSkipCount: () =>
        patchSurvey({
          surveyId: survey.id,
          surveyPatchModel: {
            additionalData: {
              ...(typeof survey.additionalData === 'object'
                ? survey.additionalData
                : {}),
              ...additionalData,
              skipCount:
                getNumber(getUnsafe(survey.additionalData, 'skipCount')) + 1,
            },
          },
        }),
      /** Used for counting page views or feature usage */
      increaseUsageCount: () =>
        patchSurvey({
          surveyId: survey.id,
          surveyPatchModel: {
            additionalData: {
              ...(typeof survey.additionalData === 'object'
                ? survey.additionalData
                : {}),
              ...additionalData,
              usageCount:
                getNumber(getUnsafe(survey.additionalData, 'usageCount')) + 1,
            },
          },
        }),
      /** Used for counting of CLI and suggestions usage */
      increaseAuroraUsageCount: () =>
        patchSurvey({
          surveyId: survey.id,
          surveyPatchModel: {
            additionalData: {
              ...(typeof survey.additionalData === 'object'
                ? survey.additionalData
                : {}),
              ...additionalData,
              auroraUsageCount:
                getNumber(
                  getUnsafe(survey.additionalData, 'auroraUsageCount')
                ) + 1,
            },
          },
        }),
      /** Used for submitting survey answers */
      answer: (answers: { value: unknown }[]) =>
        patchSurvey({
          surveyId: survey.id,
          surveyPatchModel: {
            additionalData: {
              ...(typeof survey.additionalData === 'object'
                ? survey.additionalData
                : {}),
              ...additionalData,
            },
            answers,
          },
        }),
    } as const;
  }, [additionalData, patchSurveyAsync, queryClient, survey]);
};
