import { isFeatureFlagEnabled } from '../../../../../unleash/useFeatureFlag';
import { FieldsFormValues } from '../../form-model/formModels';

const valueSourceConditions = {
  fieldType: ['simpleValue', 'lineItemSimpleValue', 'multivalue'],
};

const dataTypeConditions = {
  fieldType: ['simpleValue', 'lineItemSimpleValue', 'multivalue'],
};

const urlConditions = {
  fieldType: ['button', 'lineItemButton'],
};

const canObtainTokenConditions = {
  fieldType: ['button', 'lineItemButton'],
};

const formerAiEngineConditions = {
  fieldType: ['lineItems'],
  valueSource: ['captured', 'unset'],
};

const preFillFromConditions = {
  fieldType: ['simpleValue', 'lineItemSimpleValue'],
  valueSource: ['data'],
};

const aiEngineConditions = {
  fieldType: ['simpleValue', 'multivalue', 'lineItemSimpleValue'],
  valueSource: ['captured', 'unset'],
};

const thresholdConditions = {
  fieldType: ['lineItems'],
};

const editingConditions = {
  fieldType: ['simpleValue', 'lineItemSimpleValue', 'multivalue'],
  valueSource: ['captured', 'data'],
};

const formatConditions = {
  fieldType: ['simpleValue', 'lineItemSimpleValue', 'multivalue'],
  dataType: ['number', 'date'],
};

const optionsConditions = {
  fieldType: ['simpleValue', 'lineItemSimpleValue', 'multivalue'],
  dataType: ['enum'],
};

const tabularConditions = {
  fieldType: ['lineItemSimpleValue'],
};

const childrenConditions = {
  fieldType: ['lineItems'],
};

const formulaEditorConditions = {
  valueSource: ['formula'],
};

const fieldFromEngineConditions = {
  valueSource: ['captured'],
};

export const isFieldFromEngine = ({
  field: { valueSource },
}: FieldsFormValues) => {
  return fieldFromEngineConditions.valueSource.includes(valueSource);
};
export const isValueSourceAvailable = ({ fieldType }: FieldsFormValues) => {
  return valueSourceConditions.fieldType.includes(fieldType);
};

export const isDataTypeAvailable = ({ fieldType }: FieldsFormValues) => {
  return dataTypeConditions.fieldType.includes(fieldType);
};

export const isUrlAvailable = ({ fieldType }: FieldsFormValues) => {
  return urlConditions.fieldType.includes(fieldType);
};

export const isCanObtainTokenAvailable = ({ fieldType }: FieldsFormValues) => {
  return (
    canObtainTokenConditions.fieldType.includes(fieldType) &&
    isFeatureFlagEnabled('ac-4438-popup-for-trusted-buttons')
  );
};

export const isFormerAiEngineAvailable = ({
  fieldType,
  field: { valueSource },
}: FieldsFormValues) => {
  return (
    formerAiEngineConditions.valueSource.includes(valueSource) ||
    formerAiEngineConditions.fieldType.includes(fieldType)
  );
};

export const isAiEngineAvailable = ({
  fieldType,
  field: { valueSource },
}: FieldsFormValues) => {
  return (
    aiEngineConditions.valueSource.includes(valueSource) &&
    aiEngineConditions.fieldType.includes(fieldType)
  );
};

export const isPreFillFromAvailable = ({
  fieldType,
  field: { valueSource },
}: FieldsFormValues) => {
  return (
    preFillFromConditions.fieldType.includes(fieldType) &&
    preFillFromConditions.valueSource.includes(valueSource)
  );
};

export const isThresholdAvailable = ({ fieldType }: FieldsFormValues) => {
  return !thresholdConditions.fieldType.includes(fieldType);
};

export const isEditingAvailable = ({
  fieldType,
  field: { valueSource },
}: FieldsFormValues) => {
  return (
    editingConditions.fieldType.includes(fieldType) &&
    editingConditions.valueSource.includes(valueSource)
  );
};

export const isFormatAvailable = ({
  fieldType,
  field: { dataType },
}: FieldsFormValues) => {
  return (
    formatConditions.fieldType.includes(fieldType) &&
    formatConditions.dataType.includes(dataType)
  );
};

export const isOptionsAvailable = ({
  fieldType,
  field: { dataType },
}: FieldsFormValues) => {
  return (
    optionsConditions.fieldType.includes(fieldType) &&
    optionsConditions.dataType.includes(dataType)
  );
};

export const isChildrenAvailable = ({ fieldType }: FieldsFormValues) => {
  return childrenConditions.fieldType.includes(fieldType);
};

export const isTabularAvailable = ({ fieldType }: FieldsFormValues) => {
  return tabularConditions.fieldType.includes(fieldType);
};

export const isFormulaEditorAvailable = ({
  field: { valueSource },
}: FieldsFormValues) => {
  return formulaEditorConditions.valueSource.includes(valueSource);
};
