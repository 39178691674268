import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { getIDFromUrl, Url } from '@rossum/api-client';
import {
  AddRounded,
  ContentCopyRounded,
  DeleteRounded,
  DriveFileMoveRounded,
  EditRounded,
  SettingsRounded,
} from '@rossum/ui/icons';
import {
  Chip,
  Dialog,
  List,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from '@rossum/ui/material';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { NonAdminRestrictor } from '../../../../components/Restrictors';
import { isUserAdmin } from '../../../../redux/modules/user/selectors';
import { filterWorkspaces } from '../../../../redux/modules/workspaces/helpers';
import { useScrollToElementOnLoad } from '../../../../utils/hooks/useScrollToElementOnLoad';
import { EmptySidebar } from '../../../document-list/sidebar/components/EmptySidebar';
import { useSortWorkspacesWithQueues } from '../../../document-list/sidebar/hooks/useSortWorkspacesWithQueues';
import { useWorkspaceSorting } from '../../../document-list/sidebar/hooks/useWorkspaceSorting';
import AddQueueDialog from '../../add-queue/AddQueueDialog';
import { useWorkspacesWithQueues } from '../../hooks/useWorkspacesWithQueues';
import {
  SidebarDropdownState,
  sidebarIdleState,
  SidebarState,
} from '../SidebarState';
import { QueueDelete } from './QueueDelete';
import { QueueDuplicate } from './QueueDuplicate';
import { QueueMove } from './QueueMove';
import Workspace from './Workspace';
import { WorkspaceDelete } from './WorkspaceDelete';
import WorkspacesSkeletons from './WorkspacesSkeletons';

type SidebarQueuesProps = {
  currentQueueId?: number;
  onQueueChange: (queueId: number) => void;
  setAllDocumentsLevelActive: () => void;
  searchValue: string;
};

export const SidebarQueues = ({
  currentQueueId,
  onQueueChange,
  setAllDocumentsLevelActive,
  searchValue,
}: SidebarQueuesProps) => {
  const intl = useIntl();
  const editable = useSelector(isUserAdmin);
  const history = useHistory();

  const {
    allWorkspacesWithQueues: unsortedWorkspaces,
    isLoading,
    queues,
    workspaces,
  } = useWorkspacesWithQueues({
    enableQueries: true,
  });

  const { sorting } = useWorkspaceSorting();
  const { sortedWorkspaces } = useSortWorkspacesWithQueues({
    workspaces: unsortedWorkspaces ?? [],
    sorting,
  });

  const filteredWorkspaces = useMemo(() => {
    if (!searchValue) return sortedWorkspaces;
    return filterWorkspaces(searchValue, sortedWorkspaces);
  }, [searchValue, sortedWorkspaces]);

  const queuesLoaded = !isLoading;

  // TODO: Rewrite whole logic of automatic scrolling in the sidebar
  const { refCallback } = useScrollToElementOnLoad({
    behavior: 'smooth',
    resetAutomatically: true,
  });

  const [sidebarState, setSidebarState] =
    useState<SidebarState>(sidebarIdleState);

  const renameButtonClicked = (sidebarState: SidebarDropdownState) => {
    setSidebarState({
      ...sidebarState,
      action: 'editing',
    });
  };

  const moveQueueButtonClicked = (queueId: number, workspaceUrl: Url) => {
    setSidebarState({
      queueId,
      targetWorkspaceUrl: workspaceUrl,
      action: 'moveQueue',
    });
  };

  const [draggedName, setDraggedName] = useState<string | null>(null);
  const handleDragEnd = ({ active, over }: DragEndEvent) => {
    setDraggedName(null);
    const targetWorkspaceUrl: Url | undefined = over?.data.current?.url;

    if (
      targetWorkspaceUrl &&
      active.data.current?.workspaceUrl !== targetWorkspaceUrl
    ) {
      setSidebarState({
        queueId: active.data.current?.id,
        targetWorkspaceUrl,
        action: 'moveQueue',
      });
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    const name: string | null = event.active.data.current?.name ?? null;
    setDraggedName(name);
  };

  const deleteButtonClicked = (sidebarState: SidebarDropdownState) => {
    setSidebarState({
      ...sidebarState,
      action: 'deleting',
    });
  };

  const addQueueButtonClicked = (workspaceUrl: Url) => {
    setSidebarState({
      workspaceId: getIDFromUrl(workspaceUrl),
      workspaceUrl,
      action: 'adding',
    });
  };

  const duplicateQueueButtonClicked = (queueId: number) => {
    setSidebarState({
      queueId,
      action: 'duplicate',
    });
  };

  const setIdleState = () => setSidebarState(sidebarIdleState);

  const onSuccessQueueDelete = (deletedQueueId: number) => {
    if (currentQueueId === deletedQueueId) {
      setAllDocumentsLevelActive();
    }

    setIdleState();
  };

  const onSuccessDuplicateDelete = (duplicatedQueueId: number) => {
    onQueueChange(duplicatedQueueId);
    setIdleState();
  };

  const workspaceUrl =
    'workspaceUrl' in sidebarState ? sidebarState.workspaceUrl : undefined;
  const workspaceId = workspaceUrl ? getIDFromUrl(workspaceUrl) : undefined;

  const dropdownOpen = sidebarState.action === 'dropdown';
  const dropdownAnchorEl = dropdownOpen ? sidebarState.anchorEl : null;
  const dropdownWorkspaceHasQueues =
    dropdownOpen &&
    !sidebarState.queueId &&
    !!filteredWorkspaces?.find(workspace => workspace.id === workspaceId)
      ?.queues.length;

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const sensors = useSensors(pointerSensor);

  const queueToMove =
    sidebarState.action === 'moveQueue'
      ? queues?.find(queue => queue.id === sidebarState.queueId)
      : undefined;

  const queueToDuplicate =
    sidebarState.action === 'duplicate'
      ? queues?.find(queue => queue.id === sidebarState.queueId)
      : undefined;

  const workspaceToDelete =
    sidebarState.action === 'deleting'
      ? workspaces?.find(workspace => workspace.id === sidebarState.workspaceId)
      : undefined;

  return (
    <Stack flex={1} overflow="auto">
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <List
          sx={{ pl: 0, paddingTop: 0, paddingBottom: 0, flex: 1 }}
          component="nav"
          aria-labelledby="queues-list"
        >
          {queuesLoaded ? (
            filteredWorkspaces.length !== 0 ? (
              filteredWorkspaces.map(workspace => {
                const active = workspace.queues.some(
                  ({ id }) => id === currentQueueId
                );

                return (
                  <Workspace
                    setQueueRef={active ? refCallback : undefined}
                    key={workspace.id}
                    id={workspace.id}
                    url={workspace.url}
                    name={workspace.name}
                    currentQueueId={currentQueueId}
                    queues={workspace.queues}
                    toReviewCount={workspace.toReviewCount}
                    selectQueue={(queueId: number) => onQueueChange(queueId)}
                    active={active}
                    editable={editable}
                    // Only selected workspace receives a state, the other ones always get reset.
                    sidebarState={
                      workspaceUrl === workspace.url
                        ? sidebarState
                        : sidebarIdleState
                    }
                    searchValue={searchValue}
                    setSidebarState={setSidebarState}
                  />
                );
              })
            ) : (
              <EmptySidebar searchValue={searchValue} />
            )
          ) : (
            <WorkspacesSkeletons />
          )}
        </List>
        <DragOverlay modifiers={[snapCenterToCursor]}>
          {draggedName ? (
            <Chip
              variant="filled"
              color="primary"
              icon={<DriveFileMoveRounded fontSize="small" />}
              label={draggedName}
              sx={{ pl: 0.75, cursor: 'grabbing' }}
            />
          ) : null}
        </DragOverlay>
      </DndContext>

      <Menu
        anchorEl={dropdownAnchorEl}
        open={dropdownOpen}
        autoFocus
        MenuListProps={{ dense: true }}
        onClose={setIdleState}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={{
          exit: 0,
          enter: 250,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        {dropdownOpen && !sidebarState.queueId ? (
          <MenuItem
            onClick={() =>
              dropdownOpen && addQueueButtonClicked(sidebarState.workspaceUrl)
            }
          >
            <AddRounded sx={{ fontSize: 18, mr: 1 }} />
            <ListItemText>
              {intl.formatMessage({
                id: 'components.workspaces.menu.labels.addQueue',
              })}
            </ListItemText>
          </MenuItem>
        ) : null}
        <MenuItem
          onClick={() => dropdownOpen && renameButtonClicked(sidebarState)}
          data-cy="workspace-rename-menu-item"
        >
          <EditRounded sx={{ fontSize: 18, mr: 1 }} />
          <ListItemText>
            {intl.formatMessage({
              id: 'components.workspaces.menu.labels.rename',
            })}
          </ListItemText>
        </MenuItem>
        {dropdownOpen && sidebarState.queueId ? (
          <MenuItem
            onClick={() =>
              sidebarState.queueId &&
              moveQueueButtonClicked(
                sidebarState.queueId,
                sidebarState.workspaceUrl
              )
            }
            data-cy="queue-move-menu-item"
          >
            <DriveFileMoveRounded sx={{ fontSize: 18, mr: 1 }} />
            <ListItemText>
              {intl.formatMessage({
                id: 'components.workspaces.menu.labels.move',
              })}
            </ListItemText>
          </MenuItem>
        ) : null}
        {dropdownOpen && sidebarState.queueId ? (
          <MenuItem
            onClick={() =>
              sidebarState.queueId &&
              duplicateQueueButtonClicked(sidebarState.queueId)
            }
            data-cy="queue-duplicate-menu-item"
          >
            <ContentCopyRounded sx={{ fontSize: 18, mr: 1 }} />

            <ListItemText>
              {intl.formatMessage({
                id: 'components.workspaces.menu.labels.duplicateQueue',
              })}
            </ListItemText>
          </MenuItem>
        ) : null}
        <Tooltip
          title={
            dropdownWorkspaceHasQueues
              ? intl.formatMessage({
                  id: 'components.workspaces.menu.errors.deleteForbidden',
                })
              : ''
          }
          componentsProps={{ tooltip: { sx: { textAlign: 'left' } } }}
          placement="right"
        >
          <MenuItem
            disableRipple={dropdownWorkspaceHasQueues}
            sx={
              dropdownWorkspaceHasQueues
                ? {
                    color: theme => theme.palette.text.disabled,
                    cursor: 'not-allowed',
                  }
                : {}
            }
            onClick={
              dropdownWorkspaceHasQueues
                ? e => e.preventDefault()
                : () => dropdownOpen && deleteButtonClicked(sidebarState)
            }
            data-cy="workspace-delete-menu-item"
          >
            <DeleteRounded sx={{ fontSize: 18, mr: 1 }} />
            <ListItemText>
              {intl.formatMessage({
                id: 'components.workspaces.menu.labels.delete',
              })}
            </ListItemText>
          </MenuItem>
        </Tooltip>
        {dropdownOpen && sidebarState.queueId ? (
          <NonAdminRestrictor>
            <MenuItem
              onClick={(e: React.MouseEvent) => {
                const route = `/queues/${sidebarState.queueId}/settings`;

                if (e.metaKey || e.ctrlKey) {
                  return window.open(route, '_blank');
                }

                e.preventDefault();

                return history.push(route, {
                  backLink: history.location.pathname,
                });
              }}
              data-cy="queue-settings-menu-item"
            >
              <SettingsRounded sx={{ fontSize: 18, mr: 1 }} />
              <ListItemText>
                {intl.formatMessage({
                  id: 'components.workspaces.menu.labels.settings',
                })}
              </ListItemText>
            </MenuItem>
          </NonAdminRestrictor>
        ) : null}
      </Menu>
      <Dialog
        open={sidebarState.action === 'deleting'}
        onClose={setIdleState}
        PaperProps={{
          sx: { width: 480, minHeight: 213, position: 'fixed' },
          elevation: 2,
        }}
      >
        <>
          {sidebarState.action === 'deleting' && sidebarState.queueId ? (
            <QueueDelete
              queueId={sidebarState.queueId}
              onDelete={onSuccessQueueDelete}
              onCancel={setIdleState}
            />
          ) : null}
          {sidebarState.action === 'deleting' &&
          !sidebarState.queueId &&
          workspaceToDelete ? (
            <WorkspaceDelete
              workspace={workspaceToDelete}
              onDelete={setIdleState}
              onCancel={setIdleState}
            />
          ) : null}
        </>
      </Dialog>
      <Dialog
        open={sidebarState.action === 'moveQueue'}
        PaperProps={{
          sx: { width: 480, minHeight: 213, position: 'fixed' },
          elevation: 2,
        }}
        onClose={setIdleState}
      >
        {sortedWorkspaces.length &&
        sidebarState.action === 'moveQueue' &&
        queueToMove ? (
          <QueueMove
            queue={queueToMove}
            workspaces={sortedWorkspaces}
            targetWorkspaceUrl={sidebarState.targetWorkspaceUrl}
            onMove={setIdleState}
            onCancel={setIdleState}
          />
        ) : null}
      </Dialog>
      <Dialog
        open={sidebarState.action === 'duplicate'}
        onClose={setIdleState}
        PaperProps={{
          sx: { width: 480, minHeight: 300, position: 'fixed' },
          elevation: 2,
        }}
      >
        {queueToDuplicate && sidebarState.action === 'duplicate' ? (
          <QueueDuplicate
            queue={queueToDuplicate}
            onDuplicate={onSuccessDuplicateDelete}
            onCancel={setIdleState}
          />
        ) : null}
      </Dialog>
      <AddQueueDialog
        key={`addDialogKey-${sidebarState.action === 'adding'}`}
        open={sidebarState.action === 'adding'}
        onClose={setIdleState}
        workspaceUrl={workspaceUrl}
        onSuccess={onQueueChange}
        currentQueueId={currentQueueId}
      />
    </Stack>
  );
};
