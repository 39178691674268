/* eslint-disable react/hook-use-state */
import 'react-day-picker/lib/style.css';
import './datepickerStyles.sass';
import { Select } from '@rossum/rossum-ui/Select';
import { Button } from '@rossum/ui/material';
import clsx from 'clsx';
import {
  endOfDay,
  format,
  isAfter,
  isBefore,
  isEqual,
  isSameDay,
  startOfDay,
} from 'date-fns';
import { get, includes } from 'lodash';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DayPicker, {
  DateUtils,
  DayModifiers,
  LocaleUtils,
  NavbarElementProps,
} from 'react-day-picker';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { fromEvent } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { getEventPath } from '../../lib/DOM';
import Input from '../UI/Input';
import {
  completeHoursFormat,
  CUSTOM,
  DATE_FORMAT,
  FIRST_DAY_OF_WEEK,
  formatDateInput,
  formatHoursInput,
  formatMonthTitle,
  getShortcuts,
  getToday,
  getTranslations,
  monthTitleFormats,
  parseDateInput,
  parseHoursInput,
  predefinedShortcuts,
  TIME_FORMAT,
  TIME_FORMAT_WITH_SECONDS,
} from './helpers';
import styles from './styles.module.sass';

type DatePickerConfirmParams = {
  from: Date;
  to: Date;
  selectedOption: string;
};

type Props = {
  DatePanel: React.JSXElementConstructor<{
    visible: boolean;
    active: boolean | undefined;
    setVisible: (visible: boolean) => void;
  }>;
  active?: boolean;
  beginDate?: Date;
  datepickerPosition?: DatePickerPosition;
  defaultOption?: string;
  defaultShortcut?: string;
  endDate?: Date;
  intl: IntlShape;
  onConfirm: (params: DatePickerConfirmParams) => void;
  selectOptions?: Array<{ label: string; value: string }>;
  showHours?: boolean;
  applyButtonTitle?: DatePickerConfirmButtonTitle;
  responsive?: boolean;
  withSeconds?: boolean;
};

type DatePickerPosition = 'right' | 'left';
type DatePickerConfirmButtonTitle = 'ok' | 'applyButton';

export type PanelProps = {
  visible: boolean;
  active: boolean | undefined;
};

const Datepicker = ({
  active = true,
  beginDate = predefinedShortcuts[0].from(),
  datepickerPosition,
  defaultOption,
  defaultShortcut,
  endDate = predefinedShortcuts[0].to(),
  intl,
  onConfirm,
  selectOptions,
  showHours,
  DatePanel,
  responsive,
  applyButtonTitle = 'applyButton',
  withSeconds,
}: Props) => {
  const translations = useMemo(
    () => getTranslations(intl.locale),
    [intl.locale]
  );

  const timeFormat = withSeconds ? TIME_FORMAT_WITH_SECONDS : TIME_FORMAT;

  const inputRef = useRef<HTMLInputElement>(null);
  const pickerRef = useRef<HTMLDivElement>(null);
  const panelRef = useRef<HTMLDivElement>(null);

  const [visible, setVisible] = useState(false);
  const [settingTo, setSettingTo] = useState(false);
  const [activeShortcut, setShortcut] = useState(defaultShortcut);
  const [{ from, to }, setRange] = useState({ from: beginDate, to: endDate });
  const [inputFrom, setInputFrom] = useState(formatDateInput(from));
  const [inputTo, setInputTo] = useState(formatDateInput(to));
  const [hoursInputFrom, setHoursInputFrom] = useState(
    formatHoursInput(from, timeFormat)
  );
  const [hoursInputTo, setHoursInputTo] = useState(
    formatHoursInput(to, timeFormat)
  );
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  useEffect(() => setSelectedOption(defaultOption), [defaultOption]);

  // Date objects change reference on every render thus we need to
  // convert Date object to string and use it as a dependency in useEffect
  const fromAsString = from.toISOString();
  const toAsString = to.toISOString();
  const beginDateAsString = beginDate.toISOString();
  const endDateAsString = endDate.toISOString();

  useEffect(() => {
    setSettingTo(isSameDay(new Date(fromAsString), new Date(toAsString)));
  }, [fromAsString, toAsString]);

  useEffect(() => {
    setInputFrom(formatDateInput(new Date(fromAsString)));
  }, [fromAsString]);

  useEffect(() => {
    setInputTo(formatDateInput(new Date(toAsString)));
  }, [toAsString]);

  useEffect(() => {
    setHoursInputFrom(formatHoursInput(new Date(fromAsString), timeFormat));
  }, [fromAsString, timeFormat]);

  useEffect(() => {
    setHoursInputTo(formatHoursInput(new Date(toAsString), timeFormat));
  }, [toAsString, timeFormat]);

  useEffect(() => {
    setShortcut(defaultShortcut);
    setRange({
      from: new Date(beginDateAsString),
      to: new Date(endDateAsString),
    });
  }, [beginDateAsString, defaultShortcut, endDateAsString]);

  useEffect(() => {
    const foundShortcut = get(
      predefinedShortcuts.find(
        shortcut =>
          isSameDay(new Date(fromAsString), shortcut.from()) &&
          isSameDay(new Date(toAsString), shortcut.to())
      ),
      'key',
      CUSTOM
    );

    setShortcut(foundShortcut);
  }, [fromAsString, toAsString]);

  useEffect(() => {
    const mouseDownObserver = fromEvent(document, 'mousedown')
      .pipe(
        filter(() => visible),
        map(getEventPath),
        filter(() => !!pickerRef.current),
        filter(path => !includes(path, pickerRef.current)),
        filter(path => {
          return !includes(
            'id' in path[0] && typeof path[0].id === 'string' ? path[0].id : '',
            'react-select'
          );
        })
      )
      .subscribe(() => setVisible(false));

    return () => mouseDownObserver.unsubscribe();
  }, [visible]);

  useEffect(() => {
    if (panelRef.current) {
      const panelMouseUpObserver = fromEvent(panelRef.current, 'mouseup')
        .pipe(filter(() => active ?? false))
        .subscribe(() => setVisible(!visible));

      return () => panelMouseUpObserver.unsubscribe();
    }
    return undefined;
  });

  const handleDayClick = (
    day: Date,
    { disabled, start, end, selected }: DayModifiers
  ) => {
    if (disabled || (start && end && selected)) return;

    const range = settingTo
      ? DateUtils.addDayToRange(day, { from, to })
      : { from: day, to: day };
    setRange({ from: startOfDay(range.from!), to: endOfDay(range.to!) });
  };

  const handleInputChange =
    (setInputValue: (value: string) => void) => (value: string) =>
      setInputValue(value);

  const handleInputBlur =
    (inputType: 'inputFrom' | 'inputTo' | 'hoursInputFrom' | 'hoursInputTo') =>
    ({ target: { value } }: React.FocusEvent<HTMLInputElement>) => {
      const isChangingDate = ['inputFrom', 'inputTo'].includes(inputType);

      const validFormat = isChangingDate
        ? /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9+]{4}/.test(value)
        : withSeconds
          ? /^([0-1]?[0-9]|2[0-3])(:[0-5][0-9])?(:[0-5][0-9])?$/.test(value)
          : /^([0-1]?[0-9]|2[0-3])(:[0-5][0-9])?/.test(value);

      if (!validFormat) return;

      const isFromValue = inputType.includes('From');
      const currentValue = isFromValue ? from : to;
      const parsedValue = isChangingDate
        ? parseDateInput(value, currentValue, DATE_FORMAT)
        : parseHoursInput(
            completeHoursFormat(value, !!withSeconds),
            currentValue,
            timeFormat
          );

      const isValidChange =
        isAfter(parsedValue, new Date(1999, 11, 31)) &&
        (isFromValue
          ? !isAfter(parsedValue, to)
          : !isBefore(parsedValue, from));

      const formatValue = (value: Date) =>
        format(value, isChangingDate ? DATE_FORMAT : timeFormat);

      const formattedCurrentValue = formatValue(currentValue);
      const isValueSameString = value === formattedCurrentValue;

      if (
        (isChangingDate
          ? isSameDay(parsedValue, currentValue)
          : isEqual(parsedValue, currentValue)) &&
        isValueSameString
      ) {
        return;
      }

      if (isValidChange) {
        const range = isFromValue
          ? { from: parsedValue, to }
          : { from, to: parsedValue };

        setRange(range);

        const inputStateSetters = {
          inputFrom: setInputFrom,
          inputTo: setInputTo,
          hoursInputFrom: setHoursInputFrom,
          hoursInputTo: setHoursInputTo,
        };

        if (!isValueSameString) {
          const formattedValue = formatValue(parsedValue);

          inputStateSetters[inputType](formattedValue);
        }
      }
    };

  return (
    <div
      className={clsx(
        styles.DatepickerWrapper,
        responsive && styles.DatepickerResponsive
      )}
      ref={pickerRef}
    >
      <div ref={panelRef} data-cy="date-picker-icon">
        <DatePanel setVisible={setVisible} visible={visible} active={active} />
      </div>
      {visible && (
        <div
          className={clsx(
            styles.Datepicker,
            datepickerPosition === 'right' && styles.DatepickerRight
          )}
        >
          <div className={styles.Shortcuts}>
            {getShortcuts().map(shortcut => (
              <span
                key={shortcut.key}
                onClick={() => {
                  setShortcut(shortcut.key);

                  if (shortcut.key === CUSTOM) {
                    return inputRef.current?.focus();
                  }

                  return setRange({
                    from: shortcut.from(),
                    to: shortcut.to(),
                  });
                }}
                className={clsx(
                  styles.Shortcut,
                  activeShortcut === shortcut.key && styles.ShortcutActive
                )}
              >
                <FormattedMessage
                  id={`components.datepicker.shortcuts.${shortcut.key}`}
                />
              </span>
            ))}
          </div>
          <div className={styles.Calendars}>
            <DayPicker
              {...(monthTitleFormats[intl.locale]
                ? {
                    localeUtils: {
                      ...LocaleUtils,
                      formatMonthTitle: formatMonthTitle(intl.locale),
                    },
                  }
                : {
                    months: translations.months,
                  })}
              firstDayOfWeek={FIRST_DAY_OF_WEEK}
              numberOfMonths={2}
              selectedDays={[from, { from, to }]}
              month={from}
              toMonth={getToday()}
              disabledDays={{
                after: getToday(),
              }}
              weekdaysLong={translations.weekdaysLong}
              weekdaysShort={translations.weekdaysShort}
              modifiers={{ start: from, end: to }}
              onDayClick={handleDayClick}
              navbarElement={(props: NavbarElementProps) => (
                <div className={styles.NavbarIcons}>
                  <ChevronLeftIcon onClick={() => props.onPreviousClick()} />
                  {props.showNextButton && (
                    <ChevronRightIcon onClick={() => props.onNextClick()} />
                  )}
                </div>
              )}
            />

            <div className={styles.Bottom}>
              <div
                className={clsx(
                  styles.InputWrapper,
                  showHours && styles.HoursInputWrapper
                )}
              >
                <Input
                  className={clsx(
                    !settingTo && styles.ActiveInput,
                    showHours && styles.HoursDateInput
                  )}
                  onBlur={handleInputBlur('inputFrom')}
                  onChange={handleInputChange(setInputFrom)}
                  onFocus={() => setSettingTo(false)}
                  setRef={inputRef}
                  value={inputFrom}
                />
                {showHours && (
                  <Input
                    onFocus={() => setSettingTo(false)}
                    className={clsx(
                      styles.HoursInput,
                      !settingTo && styles.ActiveInput,
                      withSeconds && styles.WithSeconds
                    )}
                    value={hoursInputFrom}
                    onChange={handleInputChange(setHoursInputFrom)}
                    onBlur={handleInputBlur('hoursInputFrom')}
                  />
                )}
              </div>
              <span className={styles.Delimiter}>–</span>
              <div
                className={clsx(
                  styles.InputWrapper,
                  showHours && styles.HoursInputWrapper
                )}
              >
                <Input
                  className={clsx(
                    settingTo && styles.ActiveInput,
                    showHours && styles.HoursDateInput
                  )}
                  onBlur={handleInputBlur('inputTo')}
                  onChange={handleInputChange(setInputTo)}
                  onFocus={() => setSettingTo(true)}
                  value={inputTo}
                />
                {showHours && (
                  <Input
                    onFocus={() => setSettingTo(true)}
                    className={clsx(
                      styles.HoursInput,
                      settingTo && styles.ActiveInput,
                      withSeconds && styles.WithSeconds
                    )}
                    value={hoursInputTo}
                    onChange={handleInputChange(setHoursInputTo)}
                    onBlur={handleInputBlur('hoursInputTo')}
                  />
                )}
              </div>
              <div
                className={clsx(
                  styles.SelectWrapper,
                  showHours && styles.SelectHoursWrapper
                )}
              >
                {showHours && selectOptions && (
                  <Select
                    onChange={setSelectedOption}
                    options={selectOptions}
                    title="annotations.datepicker"
                    value={selectedOption ?? selectOptions[0].value}
                  />
                )}
                <Button
                  data-cy="apply-button"
                  variant="contained"
                  className={styles.ApplyButton}
                  onClick={() => {
                    setVisible(false);
                    onConfirm({
                      from,
                      to,
                      selectedOption: selectedOption ?? '',
                    });
                  }}
                  sx={{ ml: showHours && !selectOptions ? 0 : 2 }}
                >
                  <FormattedMessage
                    id={`components.datepicker.${applyButtonTitle}`}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

Datepicker.defaultProps = {
  active: true,
  beginDate: predefinedShortcuts[0].from(),
  datepickerPosition: 'left' as DatePickerPosition,
  defaultShortcut: 'today',
  endDate: predefinedShortcuts[0].to(),
  showHours: false,
};

export default injectIntl<'intl', Props>(Datepicker);
