import { endpoints } from '@rossum/api-client';
import { Engine } from '@rossum/api-client/engines';
import { useQueries } from '@tanstack/react-query';
import { useMemo } from 'react';
import * as R from 'remeda';
import { api } from '../../../lib/apiClient';
import { engineKeys } from '../keys';
import { useEngines } from './useEngines';

export const useEnginesForQueueTemplate = (
  templateName: string
): { status: 'success' | 'loading' | 'error'; data: Engine[] } => {
  const { data: allEngines, status: allEnginesStatus } = useEngines({
    type: 'extractor',
  });

  const enginesCompatibility = useQueries({
    queries: (allEngines?.results ?? []).map(engine => ({
      queryKey: engineKeys.compatibility(engine.id),
      queryFn: ({
        queryKey,
      }: {
        queryKey: ReturnType<typeof engineKeys.compatibility>;
      }) => {
        return api.request(
          endpoints.engines.checkTemplateCompatibility(queryKey[3], {
            name: templateName,
          })
        );
      },
      select: (compatibilityResult: { compatible: boolean }) => ({
        engineId: engine.id,
        compatible: compatibilityResult.compatible,
      }),
    })),
  });

  const compatibilitiesMap = R.pipe(
    enginesCompatibility,
    R.map.strict(compatibilityResult =>
      compatibilityResult.status === 'success' ? compatibilityResult.data : null
    ),
    R.filter(R.isTruthy),
    R.map.strict(({ engineId, compatible }) => [engineId, compatible] as const),
    R.fromEntries()
  );

  const returnValue = useMemo(() => {
    const returnStatus =
      allEnginesStatus === 'success' &&
      enginesCompatibility.every(
        compatibilityQuery => compatibilityQuery.status === 'success'
      )
        ? 'success'
        : allEnginesStatus === 'error' ||
            enginesCompatibility.some(
              compatibilityQuery => compatibilityQuery.status === 'error'
            )
          ? 'error'
          : 'loading';

    return {
      status: returnStatus,
      data: (allEngines ?? { results: [] }).results.filter(
        engine => !!compatibilitiesMap[engine.id]
      ),
    } as const;
  }, [allEngines, allEnginesStatus, compatibilitiesMap, enginesCompatibility]);

  return returnValue;
};
