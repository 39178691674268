import { getIDFromUrl } from '@rossum/api-client';
import { Schema } from '@rossum/api-client/schemas';
import { Box, Button, CircularProgress, Stack } from '@rossum/ui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import * as R from 'remeda';
import { formulaFieldsEnabledSelector } from '../../../../redux/modules/organizationGroup/selectors';
import { Header } from '../../../../ui/header/Header';
import { FieldFormSkeleton } from '../../../queue-settings/fields/components/form/FieldFormSkeleton';
import { ItemContent } from '../../../queue-settings/fields/components/ItemContent';
import {
  CONTAINER_WIDTH,
  FIELD_FORM_ID,
} from '../../../queue-settings/fields/constants';
import { FieldsFormModel } from '../../../queue-settings/fields/form-model/formModels';
import { toSchemaPatchPayload } from '../../../queue-settings/fields/form-model/transformations/toSchema';
import { useQueues } from '../../../queues/hooks/useQueues';
import { usePatchSchemas } from '../../hooks/usePatchSchemas';
import { FieldManagerCreateFieldParams } from '../routes';

type CreateFieldFormProps = {
  schemas: Schema[];
  rootPath: string;
};

export const CreateFieldForm = ({
  schemas,
  rootPath,
}: CreateFieldFormProps) => {
  const { push, goBack } = useHistory();
  const intl = useIntl();
  const { params } = useRouteMatch<FieldManagerCreateFieldParams>();

  const formulaFieldsEnabled = useSelector(formulaFieldsEnabledSelector);

  const schema = useMemo(() => {
    const { schemaId } = params;

    if (!schemaId || !Number(schemaId)) return undefined;

    return schemas.find(({ id }) => id === Number(schemaId));
  }, [params, schemas]);

  const { data: queues, status: queuesStatus } = useQueues({
    id: schema?.queues.map(url => getIDFromUrl(url)),
  });

  const parentId = useMemo(() => {
    const { parentId } = params;

    if (!parentId) return undefined;

    return parentId;
  }, [params]);

  const { mutation } = usePatchSchemas();
  const queryClient = useQueryClient();

  const handleSubmit = (data: FieldsFormModel) => {
    if (parentId) {
      const updatedSchema = toSchemaPatchPayload(schema, {
        op: 'add',
        parentId,
        formModel: data,
      });
      if (updatedSchema) {
        mutation.mutate([updatedSchema], {
          onSuccess: ({ fulfilled }) => {
            fulfilled.forEach(schema => {
              queryClient.setQueryData(['schema', schema.url], schema);
            });

            push(rootPath);
          },
        });
      }
    }
  };

  const formContainerRef = useRef(null);

  return (
    <Box
      sx={{
        minHeight: '100%',
        backgroundColor: theme => theme.palette.background.default,
      }}
    >
      <Header
        // TODO: fm2 - Add breadcrumbs when adding these at all FM table places
        breadcrumbs={null}
        description={intl.formatMessage({
          id: 'features.fieldManager.CreateFieldForm.description',
        })}
        onBackButtonClicked={goBack}
        scrollableDivRef={formContainerRef}
        buttons={[
          <Button
            variant="contained"
            type="submit"
            form={FIELD_FORM_ID}
            key="fm-create-field-btn"
            data-cy="fm-create-field-btn"
            startIcon={mutation.isLoading && <CircularProgress />}
          >
            {/* TODO: Might not be final label  */}
            {intl.formatMessage({
              id: 'features.fieldManager.CreateFieldForm.submitButton',
            })}
          </Button>,
        ]}
        title={intl.formatMessage({
          id: 'features.fieldManager.CreateFieldForm.title',
        })}
      />
      <Stack
        sx={{
          height: '90%',
          overflowY: 'auto',
          scrollBehavior: 'smooth',
        }}
        ref={formContainerRef}
      >
        <Stack width={CONTAINER_WIDTH} mx="auto">
          {parentId && schema && queuesStatus === 'success' ? (
            <ItemContent
              parentId={parentId}
              data={null}
              schema={schema}
              queues={queues.results}
              onSubmit={data => handleSubmit(data)}
              onDelete={R.doNothing}
              onChildrenReorder={R.doNothing}
              onQuickAction={R.doNothing}
              formulaFieldsEnabled={formulaFieldsEnabled}
            />
          ) : (
            <FieldFormSkeleton />
          )}
        </Stack>
      </Stack>
    </Box>
  );
};
