import { WithEtag } from '@rossum/api-client';
import { Queue } from '@rossum/api-client/queues';
import { Schema } from '@rossum/api-client/schemas';
import { Fade } from '@rossum/ui/material';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import { TransitionGroup } from 'react-transition-group';
import FieldsToCaptureRedesignSurvey from '../../../components/DataCaptureSurvey/FieldsToCaptureRedesignSurvey';
import { FieldPage, FieldsPage } from '../fields';
import { FieldsListSkeleton } from '../fields/components/FieldsListSkeleton';
import { FieldFormSkeleton } from '../fields/components/form/FieldFormSkeleton';
import { QueueSettingsRouteParams } from '../fields/types';

type FieldsRouteProps = RouteChildrenProps<QueueSettingsRouteParams> & {
  schema: WithEtag<Schema> | undefined;
  schemaIsLoading: boolean;
  queue: Queue;
};

export const FieldsRoute = ({
  match,
  location,
  schema,
  schemaIsLoading,
  queue,
}: FieldsRouteProps) => {
  if (!match) {
    return null;
  }

  return (
    <>
      <TransitionGroup>
        <Fade key={location.pathname} appear unmountOnExit>
          <div>
            <Switch location={location}>
              <Route path={`${match.path}/fields`} exact>
                {/* TODO: The inferred type here is wrong, maybe a newer version of router fixed it? */}
                {routeChildrenProps =>
                  schemaIsLoading || !schema ? (
                    <FieldsListSkeleton />
                  ) : (
                    <FieldsPage {...routeChildrenProps} schema={schema} />
                  )
                }
              </Route>
              <Route
                path={`${match.path}/fields/:sectionId?/:schemaItemId?/:schemaItemChildId?`}
                exact
              >
                {routeChildrenProps =>
                  schemaIsLoading || !schema ? (
                    <FieldFormSkeleton />
                  ) : (
                    <FieldPage
                      {...routeChildrenProps}
                      schema={schema}
                      queue={queue}
                    />
                  )
                }
              </Route>
              <Route path={`${match.path}`}>
                <Redirect
                  to={{
                    pathname: `${match.url}/fields`,
                    state: location.state,
                  }}
                />
              </Route>
            </Switch>
          </div>
        </Fade>
      </TransitionGroup>
      <FieldsToCaptureRedesignSurvey />
    </>
  );
};
