import { Button, CircularProgress } from '@rossum/ui/material';
import { useIsFetching, useQueryClient } from '@tanstack/react-query';
import { useIntl } from 'react-intl';
import { FORMULA_PREVIEW_KEY } from '../keys';
import { useCurrentSchemaConceptContext } from './contexts/currentSchema/currentSchemaContext';

type CalculatePreviewButtonProps = {
  disabled: boolean;
};

const CalculatePreviewButton = ({ disabled }: CalculatePreviewButtonProps) => {
  const intl = useIntl();
  const isFetching = useIsFetching({
    queryKey: [FORMULA_PREVIEW_KEY, 'annotation-content'],
  });

  const { updateSchemaForPreview } = useCurrentSchemaConceptContext();

  const queryClient = useQueryClient();

  const calculate = () => {
    updateSchemaForPreview();
    queryClient.invalidateQueries({ queryKey: [FORMULA_PREVIEW_KEY] });
  };

  const fetchingInProgress = isFetching > 0;

  const buttonDisabled = fetchingInProgress || disabled;

  return (
    <Button
      variant="outlined"
      color="secondary"
      onClick={calculate}
      disabled={buttonDisabled}
      startIcon={
        fetchingInProgress && <CircularProgress color="inherit" size={16} />
      }
      data-cy="formula-preview-calculate"
    >
      {intl.formatMessage({
        id: 'features.queueSettings.fields.formulaPreview.calculate',
      })}
    </Button>
  );
};

export default CalculatePreviewButton;
