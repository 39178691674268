import { getIDFromUrl } from '@rossum/api-client';
import { Fade, Stack, Tab, Tabs } from '@rossum/ui/material';
import { useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, RouteChildrenProps, Switch } from 'react-router';
import { TransitionGroup } from 'react-transition-group';
import { useSchema } from '../../../business/schema/useSchema';
import Scrollable from '../../../components/Scrollable';
import Queue from '../../../containers/Queue';
import AutomationDetail from '../../../containers/Queue/containers/AutomationDetail';
import QueueAccess from '../../../containers/Queue/containers/QueueAccess';
import QueueEmailsContainer from '../../../containers/Queue/containers/QueueEmails';
import QueueSchema from '../../../containers/Queue/containers/QueueSchema';
import QueueSettings from '../../../containers/Queue/containers/QueueSettings';
import UsersFooter from '../../../containers/Users/UsersFooter';
import { queueByIdSelector } from '../../../redux/modules/queues/selector';
import { enterQueue, exitQueue } from '../../../redux/modules/ui/actions';
import { HelmetComponent } from '../../../routes/HelmetComponent';
import { State } from '../../../types/state';
import { QueueSettingsHeader } from '../components/QueueSettingsHeader';
import { ScrollRestoration } from '../components/ScrollRestoration';
import { QueueSettingsContextProvider } from '../contexts/QueueSettingsContext';
import { CONTAINER_WIDTH, queueSettingsPages } from '../fields/constants';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import { usePageKey } from '../hooks/usePageKey';
import { FieldsRoute } from './FieldsRoute';

export const QueueSettingsRoute = (
  props: RouteChildrenProps<{ queueId: string }>
) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  const intl = useIntl();

  const { match, location, history } = props;

  const queueId = Number(match?.params.queueId);

  const dispatch = useDispatch();

  // TODO: fetch queue for whole route and store it in reactQuery

  // instead of onEnter/onExit on parent route
  // compatibility issue
  useEffect(() => {
    dispatch(enterQueue());

    return () => {
      dispatch(exitQueue());
    };
  }, [dispatch]);

  const currentQueue = useSelector((state: State) =>
    queueByIdSelector(state, queueId)
  );
  const currentSchemaId = getIDFromUrl(currentQueue?.schema || '');

  // TODO: Can we move this down to `FieldsRoute`?
  const { data: schema, isLoading: schemaIsLoading } = useSchema({
    schemaId: currentSchemaId,
  });

  const { currentPageKey, subPageKeys } = usePageKey();

  const { breadcrumbs } = useBreadcrumbs(
    queueId,
    subPageKeys,
    currentQueue?.name,
    schema
  );

  const tabs = useMemo(() => {
    if (subPageKeys.length > 0) return undefined;

    return (
      <Tabs
        value={currentPageKey}
        onChange={(_, value) => history.push(`${match?.url}/${value}`)}
      >
        {queueSettingsPages.map(page => (
          <Tab
            key={page}
            label={intl.formatMessage({
              id: `containers.settings.queues.queue.${page}`,
            })}
            value={page}
            data-cy={`queue-settings-header-tab-${page}`}
          />
        ))}
      </Tabs>
    );
  }, [currentPageKey, history, intl, match?.url, subPageKeys.length]);

  if (!match) {
    return null;
  }

  return (
    <>
      {currentQueue ? (
        <HelmetComponent
          dynamicName={currentQueue.name}
          translationKey="features.routes.pageTitles.queueSettings"
        />
      ) : null}
      <Switch>
        <Route path={`${match.path}/schema`}>
          <QueueSchema {...props} match={match} />
        </Route>
        <Route>
          <QueueSettingsContextProvider>
            <Scrollable ref={scrollableDivRef}>
              <Stack
                sx={{
                  minHeight: '100%',
                }}
              >
                <QueueSettingsHeader
                  scrollableDivRef={scrollableDivRef}
                  breadcrumbs={breadcrumbs}
                  tabs={tabs}
                />
                <Stack width={CONTAINER_WIDTH} mx="auto">
                  <TransitionGroup>
                    <Fade key={currentPageKey} appear unmountOnExit>
                      <div>
                        <Switch location={location}>
                          <Route path={`${match.path}/basic`}>
                            {currentQueue ? (
                              // TODO: remove <Queue /> wrapper and rewrite inner components
                              <Queue {...props} selectedQueue={currentQueue}>
                                <QueueSettings selectedQueue={currentQueue} />
                              </Queue>
                            ) : null}
                          </Route>
                          <Route path={`${match.path}/fields`}>
                            {currentQueue ? (
                              <FieldsRoute
                                {...props}
                                schema={schema}
                                queue={currentQueue}
                                schemaIsLoading={schemaIsLoading}
                              />
                            ) : null}
                          </Route>
                          <Route path={`${match.path}/emails`}>
                            {currentQueue ? (
                              // TODO: remove <Queue /> wrapper and rewrite inner components
                              <Queue {...props} selectedQueue={currentQueue}>
                                <QueueEmailsContainer
                                  selectedQueue={currentQueue}
                                />
                              </Queue>
                            ) : null}
                          </Route>
                          <Route path={`${match.path}/automation`}>
                            {currentQueue ? (
                              <AutomationDetail selectedQueue={currentQueue} />
                            ) : null}
                          </Route>
                          <Route path={`${match.path}/access`}>
                            {routeProps =>
                              currentQueue ? (
                                <Stack sx={{ p: 4 }}>
                                  <QueueAccess
                                    selectedQueue={currentQueue}
                                    {...routeProps}
                                  />
                                  <UsersFooter />
                                </Stack>
                              ) : null
                            }
                          </Route>

                          <Route
                            path={`${match.path}/`}
                            render={() => (
                              <Redirect
                                to={{
                                  pathname: `${match.url}/basic`,
                                  state: location.state,
                                }}
                              />
                            )}
                          />
                        </Switch>
                      </div>
                    </Fade>
                  </TransitionGroup>
                </Stack>
              </Stack>
            </Scrollable>
          </QueueSettingsContextProvider>
        </Route>
      </Switch>
      {/* TODO: This might be globalif we implement a proper "restoration" */}
      <ScrollRestoration scrollableContainer={scrollableDivRef.current} />
    </>
  );
};
