import { Stack } from '@rossum/ui/material';
import { ReactNode, useRef } from 'react';
import Scrollable from '../Scrollable';

const CONTAINER_WIDTH = 1024;

type PageLayoutV2Props = {
  children: ReactNode;
  renderHeader?: (params: {
    scrollableDivRef: React.RefObject<HTMLDivElement>;
  }) => ReactNode;
};

export const PageLayoutV2 = ({ children, renderHeader }: PageLayoutV2Props) => {
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  return (
    <Scrollable ref={scrollableDivRef}>
      <Stack
        sx={{
          minHeight: '100%',
          backgroundColor: t => t.palette.background.default,
        }}
      >
        {renderHeader?.({ scrollableDivRef }) ?? null}
        <Stack width={CONTAINER_WIDTH} mx="auto">
          {children}
        </Stack>
      </Stack>
    </Scrollable>
  );
};
