import { Skeleton, Stack } from '@rossum/ui/material';
import { GridRow, GridRowProps } from '@rossum/ui/x-data-grid-pro';
import { DisabledRow } from '../../pricing/components/DisabledRow';
import { AllDocsAnnotation } from '../types';

export const CustomRow = (
  props: Omit<GridRowProps, 'row'> & {
    row?: AllDocsAnnotation;
  }
) => {
  // position === 'center' means the row belongs to a column that is not pinned to the right or the left.
  return props.row?.restricted_access && props.position === 'center' ? (
    <DisabledRow {...props} />
  ) : props.row?.isOutdated ? (
    <Stack
      position="relative"
      flex={1}
      sx={{
        opacity: 0.5,
        pointerEvents: props.row.status === 'purged' ? 'none' : 'auto',
      }}
    >
      <GridRow {...props} />
      <Skeleton
        sx={{ position: 'absolute', left: 0, top: 0, zIndex: 9 }}
        width="100%"
        height="100%"
        variant="rectangular"
      />
    </Stack>
  ) : (
    <GridRow {...props} />
  );
};
