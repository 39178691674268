import { createContext, useContext } from 'react';

export const CurrentSchemaConceptContext = createContext<{
  updateSchemaForPreview: () => void;
} | null>(null);

export const useCurrentSchemaConceptContext = () => {
  const context = useContext(CurrentSchemaConceptContext);

  if (context === null) {
    throw new Error(
      '`useCurrentSchemaConceptContext` must be used within a CurrentSchemaConceptContext provider'
    );
  }

  return context;
};
