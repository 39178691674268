/* eslint-disable no-console */
import prom from 'promjs-plus';
import { useCallback, useEffect, useState } from 'react';
import { DEV_FEATURES_ENABLED } from '../constants/config';
import { sendMetricsToPrometheus } from './sendMetricsToPrometheus';

const registry = prom();

type MetricName =
  | 'frontend_document_list_load_time_seconds'
  | 'frontend_annotation_view_initially_validated_time_seconds';

type MetricsState =
  | { status: 'waiting' }
  | { status: 'started'; startTime: number }
  | { status: 'completed'; startTime: number; endTime: number };

const usePrometheusMetrics = (
  timerCondition: boolean,
  metricsMetaData: { metricName: MetricName; description: string },
  enabled: boolean
) => {
  const [metrics, setMetrics] = useState<MetricsState>({ status: 'waiting' });

  const startTimer = useCallback(() => {
    if (metrics.status === 'waiting') {
      setMetrics({ status: 'started', startTime: performance.now() });
    }
  }, [metrics.status]);

  const endTimer = useCallback(() => {
    if (metrics.status === 'started') {
      setMetrics({
        status: 'completed',
        startTime: metrics.startTime,
        endTime: performance.now(),
      });
    }
  }, [metrics]);

  useEffect(() => {
    if (enabled) {
      if (!timerCondition) {
        startTimer();
      } else {
        endTimer();
      }
    }
  }, [enabled, endTimer, metrics.status, startTimer, timerCondition]);

  useEffect(() => {
    if (metrics.status === 'completed' && enabled) {
      const { metricName, description } = metricsMetaData;

      const bucket =
        metricName === 'frontend_document_list_load_time_seconds'
          ? [0.2, 0.5, 1, 1.5, 2.5, 3, 6, 8, 10]
          : [1, 2, 3, 5, 8, 10, 11, 13, 21];

      const histogram = registry.create(
        'histogram',
        metricName,
        description,
        bucket
      );

      const time = (metrics.endTime - metrics.startTime) / 1000;

      histogram.observe(time);

      if (DEV_FEATURES_ENABLED) {
        console.log(
          `Sending metrics to prom ${metricsMetaData.metricName}: ${time}`
        );
      }

      sendMetricsToPrometheus(registry.metrics());

      histogram.resetAll();
      registry.clear();

      setMetrics({ status: 'waiting' });
    }
  }, [metrics, metricsMetaData, enabled]);
};

export default usePrometheusMetrics;
