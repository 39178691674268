import { endpoints } from '@rossum/api-client';
import { useQuery } from '@tanstack/react-query';
import { api } from '../../lib/apiClient';
import { SurveyName, surveysUuidMap } from './surveysUuidMap';

export const QUERY_KEY_SURVEYS = 'surveys';

// gets all surveys of a given `surveyName` for current user
// automatically ordered by last edits so last relevant survey is first if there are more present
// will work until there are 100+ surveys of the same type for the same user
export const useSurveys = (surveyName: SurveyName) => {
  return useQuery({
    queryKey: [QUERY_KEY_SURVEYS, surveyName] as const,

    queryFn: ({ queryKey }) =>
      api.request(
        endpoints.surveys.list({
          pageSize: 100,
          templateUuid: surveysUuidMap[queryKey[1]],
          ordering: ['-modified_at'],
        })
      ),
  });
};
