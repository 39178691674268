import { Survey } from '@rossum/api-client/surveys';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import z from 'zod';
import { SurveyName } from '../../../business/surveys/surveysUuidMap';
import { getUnsafe } from '../../../lib/helpers';
import { isNotNullOrUndefined } from '../../../lib/typeGuards';
import { State } from '../../../types/state';
import { parseAndValidate } from '../../../utils/jsonParse';
import { dataCaptureSurveyBlockersSelector } from '../selectors';
import { useLatestSurvey } from './useLatestSurvey';

type UseSurveyIfEnabledProps = {
  surveyName: SurveyName;
  userEngaged: boolean;
};

const hasUserAnsweredSurvey = (survey: Survey) =>
  survey.answers?.some(answer => answer.value !== null) ?? false;

const isUserAnnoyed = (survey: Survey, limit = 1) =>
  Number(getUnsafe(survey.additionalData, 'skipCount')) > limit;

const enoughAuroraInteractions = (survey: Survey) =>
  Number(getUnsafe(survey.additionalData, 'auroraUsageCount')) > 5;

const blockersPerSurveyName: Partial<
  Record<SurveyName, (survey: Survey) => Array<string | undefined>>
> = {
  auroraForLineItemsSurvey: survey => [
    enoughAuroraInteractions(survey) ? undefined : 'not_enough_cli_interaction',
  ],
  fieldManagerSurvey: survey => [
    enoughUsageInteractions(survey)
      ? undefined
      : 'not_enough_usage_interaction',
  ],
  fieldsToCaptureRedesignSurvey: survey => [
    enoughUsageInteractions(survey)
      ? undefined
      : 'not_enough_usage_interaction',
  ],
  formulaFieldsSurvey: survey => [
    enoughUsageInteractions(survey)
      ? undefined
      : 'not_enough_usage_interaction',
  ],
};

const annoyanceLimits: Record<SurveyName, number> = {
  dataCaptureSurvey: 1,
  auroraForLineItemsSurvey: 1,
  fieldManagerSurvey: 2,
  formulaFieldsSurvey: 2,
  fieldsToCaptureRedesignSurvey: 2,
};

const enoughUsageInteractions = (survey: Survey) =>
  Number(getUnsafe(survey.additionalData, 'usageCount')) >= 3;

const SURVEY_OVERRIDES_KEY = 'surveyOverrides';
const surveyOverridesSchema = z.record(
  z.union([z.literal('true'), z.literal('')])
);

export const useSurveyIfEnabled = ({
  surveyName,
  userEngaged,
}: UseSurveyIfEnabledProps) => {
  const surveyOverrides = parseAndValidate(
    localStorage.getItem(SURVEY_OVERRIDES_KEY),
    surveyOverridesSchema,
    {}
  );

  // Blockers for enabling / displaying surveys.
  const getSurveyDialogBlockers = useCallback(
    (survey: Survey) =>
      [
        hasUserAnsweredSurvey(survey) ? 'answered' : undefined,
        isUserAnnoyed(survey, annoyanceLimits[surveyName])
          ? 'annoyed'
          : undefined,
        ...(blockersPerSurveyName[surveyName]?.(survey) ?? []),
        !userEngaged ? 'insufficient_engagement' : undefined,
      ].filter(isNotNullOrUndefined),
    [surveyName, userEngaged]
  );

  const blockers = useSelector((state: State) =>
    dataCaptureSurveyBlockersSelector(state, surveyName)
  );

  const { survey } = useLatestSurvey({
    surveyName,
    enabled: blockers.length === 0 || surveyOverrides[surveyName] === 'true',
  });

  const viewBlockers = survey ? getSurveyDialogBlockers(survey) : undefined;

  const shouldViewSurvey = viewBlockers
    ? surveyOverrides[surveyName] === 'true' || viewBlockers.length === 0
    : false;

  return {
    survey,
    blockers,
    viewBlockers,
    shouldViewSurvey,
    surveyName,
  };
};
